//
// Transitions
// --------------------------------------------------

@mixin t-bgc {
  transition: background-color $transition-duration $transition-timing-function;
}

@mixin t-col {
  transition: color $transition-duration $transition-timing-function;
}

@mixin t-all {
  transition: all $transition-duration $transition-timing-function;
}

@mixin t-random {
  @content;
}