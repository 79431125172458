/*FONTS*/

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');

@import url('https://fonts.googleapis.com/css?family=Lato');

/*MAIN*/

.ct-googleMap--MapCol {
  width: 75%;
  float: left;
  padding-left: 0;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  @media (max-width: 767px) {
    padding-left: 15px;
  }
}

.ct-googleMap--SidebarCol {
  width: 25%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
}

.ct-googleMap {
  height: 525px;
  background-color: black;
  width: 100%;
}

.ct-googleMap--sidebar {
  text-align: left;
  width: 100%;
}

.ct-googleMap--selectContainer {
  margin-bottom: 18px;
}

.ct-googleMap--select {
  width: 100%;
  height: 40px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  font-size: 14px;
  padding: 9px 9px 9px 0;
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  /*Removes default style Firefox*/
  /*text-indent: 0.01px;*/
  /* Removes default arrow from firefox*/
  text-overflow: "";
  /*Removes default arrow from firefox*/
  color: #333;
}

.ct-googleMap--resultsCounter {
  font-size: 1.2em;
  line-height: 40px;
  padding: 0 40px 0 17px;
}

.ct-googleMap--results {
  min-height: 320px;
}

.ct-googleMap--itemCounter {
  float: right;
}

.ct-googleMap--select:focus {
  outline: none;
}

.ct-googleMap--selectContainer, .ct-googleMap--searchContainer {
  position: relative;
}

.ct-googleMap--selectContainer:after {
  content: "";
  clear: both;
}

.ct-googleMap--searchContainer {
  &:after {
    content: "";
    clear: both;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.ct-googleMap--selectContainer:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
}

.ct-googleMap--searchContainer {
  margin-bottom: 24px;
}

.ct-googleMap--sidebar {
  .ct-googleMap--search {
    height: 40px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 36px;
    color: #333;
    background-color: transparent;
    border: 1px solid #d7d6d6;
    padding-right: 15px;
    padding-left: 15px;
    &:focus {
      outline: none;
    }
  }
  .ct-googleMap--sidebarItem {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    border: none;
    padding: 8px 0 8px 17px;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: table;
    width: 100%;
    max-height: 41px;
  }
}

.ct-googleMap--sidebarItem {
  span {
    display: table-cell;
    vertical-align: middle;
  }
  .ct-googleMap--sidebarItemDistance {
    text-align: right;
    padding-left: 5px;
    width: 75px;
  }
}

.ct-googleMap--InfoWindowBody {
  padding: 15px;
  position: relative;
  background-color: #fff;
  color: #333;
}

.ct-googleMap--closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.ct-googleMap--InfoWindowBody span {
  font-size: 1.2em;
  line-height: 1.5em;
  display: block;
  position: relative;
}

.Navigation {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  &:after {
    clear: both;
  }
  li {
    display: inline-block;
  }
}

.NavigationPrev a:before, .NavigationNext a:before {
  content: "";
  height: 32px;
  width: 44px;
  display: block;
  background-position: center;
  cursor: pointer;
}

.NavigationPrev {
  float: left;
}

.NavigationNext {
  float: right;
}

.NavigationPrev.NavigationDisable a:before, .NavigationNext.NavigationDisable a:before {
  cursor: default;
}

.Navigation .paginationCounter {
  display: inline-block;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  span {
    display: inline-block;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
  }
}

.NavPage {
  padding: 0 10px;
  color: #c2bdbd;
  cursor: pointer;
  &:hover {
    color: #c2bdbd;
  }
}

/*
Styles:
    - Lollipop
    - Cosmic Blue
    - Pop
    - Retro
    - Bee Pattern
    - Dark
    - Modern
    - Material
*/

.lollipop, .cosmic, .pop, .retro, .bee, .dark, .metro, .modern, .material {
  width: 100%;
  /*1200px*/
  margin: 0 auto;
  padding: 0 15px;
  font-family: $ff-montserrat;
}

.lollipop select, .cosmic select, .pop select, .retro select, .bee select, .dark select, .metro select, .modern select, .material select, .lollipop input, .cosmic input, .pop input, .retro input, .bee input, .dark input, .metro input, .modern input, .material input {
  font-family: $ff-montserrat;;
}

/*Dark*/

.metro {
  .ct-googleMap {
    height: 549px;
  }
  .ct-googleMap--select, .ct-googleMap--sidebar .ct-googleMap--search {
    background-color: #008ea8;
    color: #f5f5f5;
    font-weight: bold;
    border: none;
  }
  .ct-googleMap--select {
    padding-left: 60px;
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/select-icon.png');
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  .ct-googleMap--selectContainer:before {
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/select-image.png');

  }
  .ct-googleMap--searchContainer:before {
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/input-image.png');
  }
  .ct-googleMap--sidebar .ct-googleMap--search {
    padding-left: 64px;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #f5f5f5;
    font-weight: bold;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #f5f5f5;
    opacity: 1;
    font-weight: bold;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #f5f5f5;
    opacity: 1;
    font-weight: bold;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #f5f5f5;
    font-weight: bold;
  }
  .ct-googleMap--sidebar .ct-googleMap--search:before {
    content: "";
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/dark/images/input-image.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 18px;
    display: block;
    position: absolute;
    top: 7px;
    left: 10px;
    z-index: 10;
  }
  .ct-googleMap--resultsCounter {
    background-color: #e6e6e6;
    color: #666666;
    font-weight: bold;
    padding-left: 35px;
    padding-right: 15px;
    line-height: 48px;
  }
  .ct-googleMap--sidebar .ct-googleMap--sidebarItem {
    background-color: #e6e6e6;
    font-weight: 400;
    margin-top: 2px;
    padding: 12px 15px 12px 35px;
    &:first-child {
      border-top: none;
    }
    &:hover {
      color: #f5f5f5;
    }
  }
  .ct-googleMap--sidebarItem .ct-googleMap--sidebarItemDistance {
    width: 80px;
    font-weight: bold;
  }
  .ct-googleMap--sidebar .ct-googleMap--sidebarItem:after{
      content: "";
      display: block;
      position: absolute;
      height: 15px;
      width: 15px;
      top: 50%;
      left: 15px;
      margin-top: -5px;
      background-image: url("#{$assetPath}js/plugins/shop-locator/src/style/metro/images/item-arrow.png");
      background-repeat: no-repeat;
      transition: all 0.2s ease;
  }
  .ct-googleMap--sidebar .ct-googleMap--sidebarItem:hover:after{
      background-image: url("#{$assetPath}js/plugins/shop-locator/src/style/metro/images/item-arrowWhite.png");
      background-repeat: no-repeat;
  }
  .ct-googleMap--results {
    margin-bottom: 20px;
  }
  .paginationCounter {
    border-left: none;
    border-right: none;
    margin: 0 auto;
  }
  .Navigation {
    background-color: #e6e6e6;
    .paginationCounter, span{
      color: #79aacf;
      font-size: 14px;
      line-height: 48px;
    }
  }
  .NavigationPrev a:before, .NavigationNext a:before {
    height: 48px;
    width: 48px;
  }
  .NavPage {
    line-height: 34px;
    padding: 8px 10px;
    color: #79aacf;
  }
  .NavigationPrev a:before {
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/paginationLeftArrow.png');
    background-repeat: no-repeat;
    border-right: 2px solid #fff;
  }
  .NavigationNext a:before {
    float: right;
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/paginationRightArrow.png');
    background-repeat: no-repeat;
    border-left: 2px solid #fff;
  }
  .NavigationPrev.NavigationDisable a:before {
    background-color: transparent;
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/paginationLeftArrow--disable.png');
    opacity: 0.7;
  }
  .NavigationNext.NavigationDisable a:before {
    background-color: transparent;
    background-image: url('#{$assetPath}js/plugins/shop-locator/src/style/metro/images/paginationRightArrow--disable.png');
    opacity: 0.7;
  }
  .ct-googleMap--customInfoWindow:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 5px;
    margin-left: -17px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 17px 0 17px;
    border-color: #fff transparent transparent transparent;
  }
  .ct-googleMap--InfoWindowBody{
      span{
        color: #666666;
        &:first-child{
          font-weight: bold;
        }
      }
  }
  .active{
      font-weight: bold;
  }
}


/*RESPONSIVE(MEDIA QUERIES)*/

@media (max-width: 1200px) {
  .lollipop, .cosmic, .pop, .retro, .bee, .dark, .metro, .modern, .material {
    width: 100%;
  }
  .ct-googleMap--SidebarCol {
    width: 40%;
  }
  .ct-googleMap--MapCol {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .ct-googleMap--SidebarCol, .ct-googleMap--MapCol {
    width: 100%;
  }
  .ct-googleMap--sidebar {
    margin-bottom: 30px;
  }
}
