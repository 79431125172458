/* Newsletter */

.ct-newsletter {
  font-family: $ff-montserrat;
  label {
    color: #fff;
    text-transform: uppercase;
    padding-right: 23px;
    @include font-size(1.4);
  }
  .inner {
    position: relative;
    display: inline-block;
  }
  .form-control {
    background-color: transparent;
    border: 2px solid #8d8d8d;
    height: 60px;
    padding-left: 32px;
    padding-right: 40px;
    @include mixin-color-rgba(0,0,0, .9);
    @include box-shadow(none);
    @include font-size(1.4);
    @include border-radius(50px);
    @include placeholder {
      @include mixin-color-rgba(255,255,255, .5);
    }
    &:focus {
      @include box-shadow(none);
      outline: none;
      background-color: #fff;
      + button[type="submit"] {
        @include mixin-color-rgba(0,0,0, .9);
      }
      @include placeholder {
        @include mixin-color-rgba(0,0,0, .9);
      }
    }
    &.is-not-empty {
      background-color: #fff;
      + button[type="submit"] {
        @include mixin-color-rgba(0,0,0, .9);
      }
      @include placeholder {
        @include mixin-color-rgba(0,0,0, .9);
      }
    }
  }
  button[type="submit"] {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -10px;
    background: none;
    border: 0 none;
    padding: 0;
    line-height: 1;
    display: inline-block;
    width: 20px;
    height: 20px;
    @include mixin-color-rgba(255,255,255, .5);
    @include font-size(2.0);
  }

  // White newsletter

  &.ct-newsletter--white {
    .form-control {
      border-color: #fff;
      @include placeholder{
        color: #fff;
      }
    }
    button[type="submit"] {
      color: #fff;
    }
  }
}


// Contact Form

.ct-contactForm {
  .form-group{
    position: relative;
    margin-bottom: 30px;
  }
  .form-control{
    &::placeholder{
      text-transform: uppercase;
    }
  }
  .form-control, .selectize-input {
    background-color: transparent;
    border: 2px solid #939393;
    font-family: $ff-montserrat;
    // text-transform: uppercase;
    min-height: 45px;
    padding-left: 32px;
    @include border-radius(50px);
    @include t-all;
    &:focus {
      background-color: #fff;
      @include mix-box-shadow-reset;
    }
    &.is-not-empty {
      background-color: #fff;
    }
  }
  .selectize-input{
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  textarea {
    resize: none;
    padding-top: 11px;
  }
  button[type="submit"] {
    position: absolute;
    top: 50%;
    right: 11px;
    color: #fff;
    @include mix-transform(translateY(-50%));
  }

  .ct-contactForm-requiredMessage {
    position: absolute;
    font-family: $ff-montserrat;
    color: #535353;
    padding-top: 5px;
    padding-left: 35px;
    text-transform: uppercase;
    @include font-size(1.2);
  }
  .alert {
    font-family: $ff-montserrat;
    text-transform: uppercase;
    padding-left: 35px;
    @include border-radius(50px);
  }

  // variant white

  &.ct-contactForm--white {
    .form-control, .selectize-input {
      border-color: #fff;
      color: #fff;
      @include placeholder {
        color: #fff;
      }
      &:focus, &.dropdown-active {
        color: #000;
        @include placeholder {
          color: #000;
        }
      }
    }
    .selectize-input{
      &:after{
        border-color: #ffffff transparent transparent transparent;
      }
      &.dropdown-active{
        &:after{
          border-color: #000000 transparent transparent transparent;
        }
      }
    }
  }

  &.form-inline {
    .form-group {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  @include sm-min {
    .form-control, .selectize-input{
      min-height: 60px;
    }
    textarea{
      padding-top: 18px;
    }
    .selectize-input{
      padding-top: 19px;
      padding-bottom: 19px;
    }
  }
}

// Search form // ------------------------------------------------------------------------------------------------------

.ct-searchForm {
  display: table;
  z-index: 9999;
  text-align: center;
  @include t-random {
    transition: all 0.5s ease-in-out;
  }
  @include mixin-background-color(0,0,0,.9);
  @include opacity(0);
  @include full-fixed;
  @include mix-transform(translate(0px, -100%) scale(0,0));
  &.is-open {
    @include mix-transform(translate(0px, 0px) scale(1,1));
    @include opacity(1);
  }
  .inner {
    display: table-cell;
    vertical-align: middle;
  }
  .form-group {
    position: relative;
    margin-bottom: 0;
  }
  .form-control {
    background-color: transparent;
    @include border-radius(0);
    border: 0 none;
    border-bottom: 2px solid #fff;
    height: auto;
    padding: 15px 35px;
    color: #fff;
    font-weight: 300;
    font-family: $ff-open-sans;
    @include font-size(3.6);
    @include font-size(3.6);
    @include placeholder{
      color: #fff;
      font-weight: 300;
      font-family: $ff-open-sans;
      @include font-size(3.6);
    }
    &:focus {
      outline: none;
      @include mix-box-shadow-reset;
    }
  }
  .ct-searchForm-close {
    margin-top: 50px;
    display: inline-block;
    svg {
      path {
        @include t-all;
      }
    }
  }
  .ct-search-btn {
    position: absolute;
    top: 50%;
    right: 30px;
    border: 0 none;
    background-color: transparent;
    color: #fff;
    @include font-size(2.0);
    @include mix-transform(translateY(-50%));
  }
}

.ct-searchForm--default{
  .form-group {
    position: relative;
    margin-bottom: 0;
  }
  .form-control {
    background-color: transparent;
    @include border-radius(0);
    border: 0 none;
    border-bottom: 2px solid #fff;
    height: auto;
    padding: 10px 35px 10px 10px;
    color: #fff;
    font-weight: 300;
    font-family: $ff-open-sans;
    @include font-size(3.6);
    @include font-size(3.6);
    @include placeholder {
      color: #fff;
      font-weight: 300;
      font-family: $ff-open-sans;
      @include font-size(3.6);
    }
      @include mix-box-shadow-reset;
    &:focus {
      outline: none;
      @include mix-box-shadow-reset;
    }
  }
  .ct-search-btn {
    position: absolute;
    top: 50%;
    right: 0;
    border: 0 none;
    background-color: transparent;
    color: #e6e6e6;
    @include font-size(2.0);
    @include mix-transform(translateY(-50%));
    &:hover{
      color: #fff;
    }
  }
  @include sm-min {
    .form-control{
      padding: 15px 75px 15px 35px;
    }
    .ct-search-btn{
      right: 30px;
    }
  }
}


// Add to cart Form // -------------------------------------------------------------------------------------------------

.ct-addToCart {
  .form-group {
    position: relative;
    .ct-addToCart-logic {
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -14px;
      span{
        cursor: pointer;
      }
    }
    .ct-input-increment,
    .ct-input-decrement{
      margin: 0 3px;
      color: #fff;
      @include font-size(2.0);
    }
    @include sm-max {
      &:first-child{
        margin-bottom: 10px;
      }
      .ct-addToCart-logic{
        margin-top: -12px;
      }
    }
  }
}

// Alert // -------------------------------------------------------------------------------------------------

.alert{
  font-family: $ff-montserrat;
  border-radius: 50px;
  text-align: center;
  position: relative;
  .close{
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -12px;
  }
  @include sm-min {
    width: 50%;
    margin: 0 auto 20px auto;
    padding: 19px 35px;
  }
}

.ct-dividerSection, .ct-footer{
  .alert{
    width: 100%;
  }
}
