@each $color_name, $color_value in $colors {
  .ct-u-background--#{$color_name} {
    background-color: $color_value !important;
  }
  .ct-u-color--#{$color_name} {
    color: $color_value !important;
  }
}


// Background-color[rgba]:

@mixin mixin-background-color($r, $g, $b, $a){
  background-color: rgb($r, $g, $b);
  background-color: rgba($r, $g, $b, $a);
}

// Font-color[rgba]

@mixin mixin-color-rgba($r, $g, $b, $a){
  color: rgb($r, $g, $b);
  color: rgba($r, $g, $b, $a);
}