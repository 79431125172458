html,
body,
img,
figure {
  max-width: 100%;
}

html,
body {
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

a {
  transition: all, $transition-duration, $transition-timing-function;
}

button,
.btn {
  transition: background-color, $transition-duration, $transition-timing-function;
}

* {
  outline: none !important;
}

ul {
  margin-bottom: 0;
}

[data-background],
[data-parallax] {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

[data-parallax] {
  background-attachment: fixed;
  .mobile &,
  .tablet & {
    background-attachment: scroll!important;
  }
}

.media-object {
  max-width: none;
}

.gm-style * {
  touch-action: inherit !important;
}

.ct-slick {
  .slick-track,
  .slick-list,
  .item,
  .slick-item {
    min-height: inherit;
  }
}

@include sm-min {
  .dropdown:hover>ul.dropdown-menu {
    display: block;
  }
}

;
//@media (min-width: 1200px) {
//  .container {
//    width: 1200px;
//  }
//}
.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.pull-right {
  &.ct-u-reset--floatSM {
    @include sm-max {
      float: none!important;
    }
  }
}

.ct-fs--italic {
  font-style: italic;
}

//== HTML Font Sizes
//
//## Used for REM calculations
html {
  font-size: 50%;
}

@include sm-min {
  html {
    font-size: 55.5555%
  }
}

@include md-min {
  html {
    font-size: 62.5%
  }
}

h1,
.h1 {
  @include font-size(5.5);
  small {
    @include font-size(3.9);
    font-weight: inherit;
    color: inherit;
  }
}

h2,
.h2 {
  @include font-size(3.9);
}

h3,
.h3 {
  @include font-size(3.6)
}

h4,
.h4 {
  @include font-size(1.9);
  line-height: 1.5;
}

h5,
.h5 {
  @include font-size(1.7);
}

h6,
.h6 {
  @include font-size(1.4);
}

header {
  @include font-size(1.8);
}

p {
  &.lead {
    font-weight: 400;
    color: #666;
    @extend .h4;
  }
}
