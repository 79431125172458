//
// Font Mixins
// --------------------------------------------------


//== Font Family
//
//##

@mixin font-base {
  font-family: $font-family-sans-serif;
}

@mixin font-secondary {
  font-family: $font-family-secondary;
}


//== Font Weights
//
//##

@each $font-weight in $font-weights {
  .ct-u-font-weight--#{$font-weight} {
    font-weight: $font-weight !important;
  }
}


//== Font Sizes
//
//##

@each $font-size in $font-sizes {
  .ct-u-font-size--#{$font-size} {
    font-size: $font-size + px !important;
  }
}


//== Rem

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

