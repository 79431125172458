//
// Element Full Height
// --------------------------------------------------

@mixin full-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin full-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//
// Overflows
// --------------------------------------------------

@mixin overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.ct-u-overflow-ellipsis {
  @include overflow-ellipsis;
}
.ct-u-overflow-hidden {
  overflow: hidden;
}
.ct-u-overflow-visible {
  overflow: visible;
}

//
// Text Color
// --------------------------------------------------
@mixin text-color($value) {
  color: $value;
  &:hover, &.hover,
  &:active, &.active,
  &:focus, &.focus {
    color: $value;
  }
}

// Border radius

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Placeholder

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin t-random () {
  @content
}

// Box shadow

@mixin mix-box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}
@mixin mix-box-shadow-reset() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

// Transform

@mixin mix-transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// Triangle generator

@mixin triangle($type, $width, $height, $color){

  $width : $width;
  $height : $height;
  $width-half : $width/2;
  $height-half : $height/2;

  width: 0;
  height: 0;
  border-style: solid;

  @if $type == 'top' {

    border-width: 0 $width-half $height $width-half;
    border-color: transparent transparent $color transparent;

  } @else if ($type == 'top-right') {

    border-width: 0 $width $height 0;
    border-color: transparent $color transparent transparent;

  } @else if($type == 'right') {

    border-width: $height-half 0 $height-half $width;
    border-color: transparent transparent transparent $color;

  } @else if ($type == 'bottom-right') {

    border-width: 0 0 $height $width;
    border-color: transparent transparent $color transparent;

  } @else if ($type == 'bottom') {

    border-width: $height $width-half 0 $width-half;
    border-color: $color transparent transparent transparent;

  } @else if($type == 'botem-left'){

    border-width: $height 0 0 $width;
    border-color: transparent transparent transparent $color;

  } @else if ($type == 'left') {

    border-width: $height-half $width $height-half 0;
    border-color: transparent $color transparent transparent;

  } @else if ($type == 'top-left') {

    border-width: $height $width 0 0;
    border-color: $color transparent transparent transparent;
  }
}