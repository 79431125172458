//** Accordion
.panel-group {
  @include sm-min {
    // margin-top: 50px;
    .panel+.panel, .panel{
      margin: 0 0 30px 0;
    }
  }
}
.panel{
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.panel-default{
  border-color: transparent;
  margin-bottom: 30px;
  >.panel-heading{
    font-family: $ff-montserrat;
    text-transform: uppercase;
    background-color: transparent;
    border-color: transparent;
    padding: 0 0 0 70px;
    a{
      @include font-size(1.4);
      color: #333333;
      font-weight: 700;
      display: block;
      padding: 22px 0 23px 15px;
      position: relative;
      line-height: 24px;
      border-bottom: 1px solid #e6e6e6;
      &:before{
        content: '\f068';
        font-family: fontawesome;
        text-align: center;
        font-size: $font-size-base;
        line-height: 70px;
        height: 70px;
        width: 70px;
        color: #333333;
        background-color: transparent;
        border: 1px solid #e6e6e6;
        display: block;
        position: absolute;
        left: -70px;
        top: 50%;
        margin-top: -35px;
        @include  t-all;
      }
    }
    .collapsed{
      &:before{
        content: '\f067';
        background-color: transparent;
      }
    }
  }
  .panel-heading+.panel-collapse{
    .panel-body{
      border-top: none;
      padding: 0 0 0 69px;
      p{
        font-style: italic;
        @include font-size(1.8);
        color: #666666;
        margin: 0;
        padding: 30px;
        border-left: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}

.browser-mozilla{
  .panel-default > .panel-heading a{
    padding: 22px 0 22px 15px;
  }
}
