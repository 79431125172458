/*------------------------------------------------------------------
Project:	Disrupt HTML theme
Version:	1.0
Last change:	23.02.2016
-------------------------------------------------------------------*/


/*------------------------------------------------------------------

[Table of contents]
// 1. variables
// 2. mixins
// 3. animate
// 4. slick
// 5. selectize
// 6. global
// 7. util
// 8. lightgallery
// 9. lg-transitions
// 10. date-picker
// 11. shop-locator
// 12. navbar
// 13. footer
// 14. buttons
// 15. contact-form
// 16. toggleables
// 17. tooltip
// 18. typography
-------------------------------------------------------------------*/

//
// Includes
// --------------------------------------------------
// Fonts // ------------------------------------------------------------------------------------------------------------
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic,300&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700");
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville&subset=latin,latin-ext");
$assetPath: '../';
//** Mixins and Variables
@import 'variables';
@import 'bootstrap/bootstrap/mixins';
@import 'mixins';
//== Plugins
//
//##
//** Animate CSS
@import 'animate-css/animate';
//** Slick
@import 'plugins/slick/slick';
@import 'plugins/slick/slick-theme';
//** Selectize
@import 'plugins/selectize/selectize.bootstrap3';
//** Global
@import 'global';
@import 'utilities';
//** Plugins
@import "plugins/lightgallery/lightgallery";
@import "plugins/lightgallery/lg-transitions";
@import "plugins/date-picker/date-picker";
@import "plugins/shop-locator/shop-locator";
//== Components
//
//##
@import 'components/navbar';
@import "components/footer";
@import "components/buttons";
@import "components/contact-forms";
@import "components/datepicker";
@import "components/toggleables";
//** Motive
@import "_motive";

/**
 * Comment compiled to CSS example
 */

html.browser-mozilla {
  .ct-mediaSection {
    &.ct-u-display-table {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ct-mediaSection {
  position: relative;
}

.img-responsive {
  margin: 0 auto;
  @include xs-max {
    width: 70%;
  }
}

.ct-u-indentInvisible {
  text-indent: -9999px;
}

.ct-u-margin-top-20--minus {
  margin-top: -20px;
}

.ct-u-margin-top-70--minus {
  margin-top: -70px;
}

.ct-u-margin-top-20--minus,
.ct-u-margin-top-70--minus {
  display: block!important;
  @media (max-width: 1199px) {
    margin-top: 0;
  }
}

.ct-col-darkOverlay {
  position: relative;
  z-index: 2;
  &:before {
    content: '';
    z-index: -1;
    @include mixin-background-color(0,
    0,
    0,
    .8);
    @include full-absolute;
  }
}

.ct-sectionOverlay-75 {
  position: relative;
  z-index: 2;
  &:before {
    content: '';
    z-index: -1;
    @include mixin-background-color(0,
    0,
    0,
    .75);
    @include full-absolute;
  }
}

.animation-infinity {
  -webkit-animation: pulse 1s infinite;
  -moz-animation: pulse 1s infinite;
  -o-animation: pulse 1s infinite;
  animation: pulse 1s infinite;
}

.cssAnimate .animated {
  @include opacity(0);
}

.cssAnimate .activate {
  @include opacity(1);
}

// Header // -----------------------------------------------------------------------------------------------------------
html.browser-mozilla {
  .ct-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ct-header {
  display: table;
  width: 100%;
  .inner {
    display: table-cell;
    vertical-align: middle;
    padding-top: 80px;
  }
  .ct-header-title {
    color: #fff;
    font-weight: 300;
    margin-top: 0;
    float: left;
  }
  .breadcrumb {
    font-family: $ff-montserrat;
    background: 0 none;
    color: #fff;
    padding: 13px 0 0;
    text-transform: uppercase;
    font-weight: 700;
    float: right;
    @include font-size(1.1);
    >.active {
      color: #fff;
    }
    li {
      &:before {
        padding: 0 15px;
      }
    }
  }
  @include xs-max {
    .ct-header-title,
    .breadcrumb {
      float: none;
      text-align: center;
    }
  }
}

// Socials // ----------------------------------------------------------------------------------------------------------
.ct-socials {
  li {
    padding: 0 40px 15px 0;
    @include md-max {
      padding-right: 20px;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      line-height: 24px;
      @include mixin-color-rgba(255,
      255,
      255,
      .25);
      @include font-size(3.0);
      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }
    }
  }
}

.ct-socials--rounded {
  li {
    a {
      display: block;
      width: 37px;
      height: 37px;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, .4);
      color: #fff;
      line-height: 36px;
      text-align: center;
      @include border-radius(50%);
      &:hover,
      &:focus {
        background-color: #000;
        border-color: #000;
      }
    }
  }
}

.ct-socials--motive {
  li {
    padding: 0;
    margin-right: 13px;
    &:last-child {
      margin-right: 0;
    }
    a {
      display: block;
      width: 37px;
      height: 37px;
      color: #333;
      text-align: center;
      line-height: 37px;
      border: 1px solid orange;
      @include border-radius(50%);
      @include font-size(1.4);
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}

// Tooltips // ---------------------------------------------------------------------------------------------------------
.tooltip {
  font-family: $ff-montserrat;
  .tooltip-inner {
    text-transform: uppercase;
    padding: 5px 15px;
    @include border-radius(25px);
  }
  &.left {
    .tooltip-arrow {
      right: 1px;
    }
  }
  &.right {
    .tooltip-arrow {
      left: 1px;
    }
  }
}

// Section Headers // --------------------------------------------------------------------------------------------------
.ct-sectionHeader {
  .ct-sectionHeader-subtitle {
    padding-top: 27px;
  }
  // type light
  &.ct-sectionHeader--typeLight {
    .ct-sectionHeader-title {
      font-weight: 300;
    }
    .ct-sectionHeader-title,
    .ct-sectionHeader-subtitle {
      color: #fff;
    }
  }
  // type darken
  &.ct-sectionHeader--typeDarken {
    .ct-sectionHeader-title {
      font-weight: 300;
    }
    .ct-sectionHeader-subtitle {
      color: #666666;
    }
  }
  // type italic subtitle
  &.ct-sectionHeader--subtitleItalic {
    .ct-sectionHeader-subtitle {
      font-style: italic;
    }
  }
  .ct-sectionHeader-title {
    color: #191919;
    span {
      color: #666666;
    }
  }
}

// Gallery // ----------------------------------------------------------------------------------------------------------

/*
SUPPORT FOR COL4, COL3, COL2 needed
*/

.ct-gallery {
  .ct-gallery-item {
    display: block;
    @include t-random {
      transition: none;
    }
    img {
      width: 100%;
    }
    .ct-gallery-item-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      @include t-random {
        transition: visibility 0s linear 0.2s, opacity 0.2s linear;
      }
      ;
      .ct-u-display-table {
        height: 100%;
        .ct-u-display-tableCell {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    // Gallery item zoomer
    .ct-item-zoomer {
      .ct-item-zoomer-icon {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 1px solid #fff;
        color: #fff;
        line-height: 70px;
        @include font-size(2.0);
        @include border-radius(50%);
        @include t-all;
      }
      .ct-item-zoomer-title {
        position: relative;
        color: #fff;
        font-family: $ff-montserrat;
        text-transform: uppercase;
        margin-bottom: 0;
        @include t-all;
        @include font-size(1.4);
      }
    }
    @include sm-min {
      .ct-gallery-item-inner {
        visibility: hidden;
        @include opacity(0);
      }
      &:hover {
        .ct-gallery-item-inner {
          @include opacity(1);
          visibility: visible;
          transition-delay: 0s;
          .ct-item-zoomer {
            .ct-item-zoomer-icon {
              top: 0;
              @include opacity(1);
            }
            .ct-item-zoomer-title {
              bottom: 0;
              @include opacity(1);
            }
          }
        }
      }
      .ct-item-zoomer {
        .ct-item-zoomer-ico,
        .ct-item-zoomer-title {
          @include opacity(0);
        }
        .ct-item-zoomer-icon {
          top: -35px;
        }
        .ct-item-zoomer-title {
          bottom: -35px;
        }
      }
    }
  }
}

[class*="ct-gallery--col"] {
  .ct-gallery-item {
    float: left;
    display: block;
    img {
      width: 100%;
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// 5items in row
.ct-gallery--col5 {
  .ct-gallery-item {
    width: 20%;
    @include lg-max {
      width: 33.333333%;
    }
    @include md-max {
      width: 50%;
    }
    @include sm-max {
      width: 100%;
    }
  }
}

// 4items in row
.ct-gallery--col4 {
  .ct-gallery-item {
    width: 25%;
    @include lg-max {
      width: 50%;
    }
    @include md-max {
      width: 50%;
    }
    @include xs-max {
      width: 100%;
    }
  }
}

// 3items in row
.ct-gallery--col3 {
  .ct-gallery-item {
    width: 33.333333%;
    @include lg-max {
      width: 50%;
    }
    @include md-max {
      width: 50%;
    }
    @include sm-max {
      width: 100%;
    }
  }
}

// 2items in row
.ct-gallery--col2 {
  .ct-gallery-item {
    width: 50%;
    @include lg-max {
      width: 50%;
    }
    @include md-max {
      width: 50%;
    }
    @include sm-max {
      width: 100%;
    }
  }
}

// 1item in row
.ct-gallery--col1 {
  .ct-gallery-item {
    width: 100%;
  }
}

// Light gallery // ----------------------------------------------------------------------------------------------------
.ct-light-gallery {
  .lg-actions {
    .lg-next,
    .lg-prev {
      background-color: #fff;
      @include opacity(.6);
      &:after,
      &:before {
        background-color: #fff;
        color: #000;
      }
      &:before {
        color: #000;
      }
    }
    .lg-next {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      &:after {
        content: 'Next';
        width: 80px;
        height: 100%;
        display: block;
        left: -82px;
        top: 0;
        position: absolute;
        text-align: center;
        padding-top: 11px;
        padding-left: 3px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        -webkit-transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -o-transition: opacity .5s ease-in-out;
        transition: opacity .5s ease-in-out;
        font-family: $ff-montserrat;
        @include font-size(1.8);
        @include opacity(0);
      }
      &:hover {
        &:after {
          @include opacity(1);
        }
      }
    }
    .lg-prev {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      &:before {
        content: 'Prev';
        width: 80px;
        height: 100%;
        display: block;
        right: -82px;
        top: 0;
        position: absolute;
        text-align: center;
        padding-top: 11px;
        padding-right: 3px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        -webkit-transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -o-transition: opacity .5s ease-in-out;
        transition: opacity .5s ease-in-out;
        font-family: $ff-montserrat;
        @include font-size(1.8);
        @include opacity(0);
      }
      &:hover {
        &:before {
          @include opacity(1);
        }
      }
    }
  }
  // Thumbnails
  .lg-thumb {
    margin: 0 auto;
    .lg-thumb-item {
      position: relative;
      border: 0 none;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include mixin-background-color(0,
        0,
        0,
        .5);
        @include t-all;
      }
      &.active {
        &:before {
          display: none;
        }
      }
      &:hover,
      &:focus,
      &:active {
        &:before {
          @include mixin-background-color(0,
          0,
          0,
          0);
        }
      }
      &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
      &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }
  }
  // Counter
  #lg-counter {
    font-family: $ff-montserrat;
  }
}

// Brand box // --------------------------------------------------------------------------------------------------------
.ct-brandBox {
  display: table;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 20px 0 10px 0;
  figure {
    display: table-cell;
    vertical-align: middle;
    img {
      margin: 0 auto;
    }
  }
  figcaption {
    font-family: $ff-montserrat;
    padding-top: 15px;
    text-transform: uppercase;
    @include opacity(0);
    visibility: hidden;
    @include t-random {
      transition: visibility 0s linear 0.250s, opacity 0.250s linear;
    }
    ;
  }
  &:hover {
    @include mixin-background-color(51,
    51,
    51,
    0.02);
    figcaption {
      visibility: visible;
      transition-delay: 0s;
      @include opacity(1);
    }
  }
}

html.browser-mozilla {
  .ct-brandBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Icon Box // ---------------------------------------------------------------------------------------------------------
.ct-iconBox {
  //type 1
  .ct-iconBox-icon {
    margin: 0 auto;
  }
  &.ct-iconBox--type1 {
    text-align: center;
    margin-bottom: 50px;
    .ct-iconBox-icon {
      width: 110px;
      height: 110px;
      text-align: center;
      margin: 0 auto;
      line-height: 111px;
      @include font-size(3.0);
      @include border-radius(50%);
      @include t-all;
      @include sm-max {
        width: 70px;
        height: 70px;
        line-height: 70px;
      }
    }
    .ct-iconBox-description+.ct-iconBox-icon {
      margin-top: 25px;
    }
    .ct-iconBox-title {
      font-family: $ff-montserrat;
      margin-bottom: 3px;
      padding-top: 18px;
    }
    .ct-iconBox-title,
    .ct-iconBox-description {
      text-transform: uppercase;
      @include font-size(1.4);
    }
    .ct-iconBox-description {
      color: #666;
    }
    &.ct-iconBox--variant {
      .ct-iconBox-description {
        padding-top: 15px;
        text-transform: inherit;
        color: #666;
        line-height: 30px;
      }
    }
    &.ct-iconBox--white {
      .ct-iconBox-icon {
        color: #fff;
      }
      .ct-iconBox-title,
      .ct-iconBox-description {
        color: #fff;
      }
    }
    &:hover {
      .ct-iconBox-icon {
        color: #fff;
      }
    }
  }
  //type 2
  &.ct-iconBox--type2 {
    .media-left,
    .media-right {
      vertical-align: middle;
      @include sm-max {
        display: block;
      }
    }
    .media-left {
      width: 10000px;
      zoom: 1;
      overflow: hidden;
      @include sm-max {
        width: auto;
        overflow: visible;
      }
    }
    .media-body {
      @include sm-max {
        text-align: center;
      }
    }
    .ct-iconBox-icon {
      text-align: center;
      width: 70px;
      height: 70px;
      line-height: 70px;
      @include font-size(2.0);
      @include border-radius(50%);
      @include t-all;
      @include sm-max {
        margin: 0 auto;
      }
    }
    .ct-iconBox-title,
    .ct-iconBox-description {
      text-transform: uppercase;
      @include font-size(1.4);
    }
    .ct-iconBox-title {
      margin-bottom: 5px;
      font-family: $ff-montserrat;
    }
    // right variant
    &.ct-iconBox--right {
      @include sm-max {
        position: relative;
        overflow: visible;
        padding-top: 80px;
      }
      .media-left {
        text-align: right;
        @include sm-max {
          text-align: center;
          padding-right: 0;
        }
      }
      .media-right {
        @include sm-max {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -35px;
          padding-left: 0;
        }
      }
    }
    //white variant
    &.ct-iconBox--white {
      .media-left {
        .ct-iconBox-title,
        .ct-iconBox-description {
          color: #fff;
        }
      }
      .ct-iconBox-icon {
        color: #fff;
      }
    }
    // dark variant
    &.ct-iconBox--dark {
      .media-left {
        width: auto;
        overflow: visible;
      }
    }
    //default variant
    &.ct-iconBox--default {
      .media-left {
        width: auto;
        overflow: visible;
      }
      .media-left,
      .media-body {
        vertical-align: middle;
      }
      .ct-iconBox-icon {
        border-color: #fff;
      }
      .ct-iconBox-title,
      .ct-iconBox-description {
        color: #fff;
      }
    }
    +.ct-iconBox--type2 {
      margin-top: 40px;
    }
    &:hover {
      .ct-iconBox-icon {
        color: #fff;
      }
    }
  }
  // type 3
  &.ct-iconBox--type3 {
    .media-left {
      padding-right: 35px;
      img {
        max-width: none;
      }
    }
    .media-body {
      padding-top: 4px;
      vertical-align: middle;
      .ct-iconBox-title {
        font-weight: 300;
        margin-bottom: 3px;
        @include font-size(3.6);
      }
      .ct-iconBox-description {
        font-weight: 700;
        @include font-size(1.8);
      }
      .ct-iconBox-title,
      .ct-iconBox-description {
        text-transform: uppercase;
      }
    }
    &.ct-iconBox--white {
      color: #fff;
    }
    @include sm-max {
      .media-left,
      .media-body {
        display: block;
        width: auto;
        text-align: center;
      }
      .media-left {
        padding-right: 0;
      }
    }
  }
  // type 4
  &.ct-iconBox--type4 {
    padding-top: 25px;
    padding-bottom: 10px;
    .media-body,
    .media-left {
      vertical-align: baseline;
    }
    .media-left {
      padding-right: 20px;
    }
    .ct-iconBox-icon {
      text-align: center;
      width: 70px;
      height: 70px;
      line-height: 70px;
      padding-left: 2px;
      @include t-all;
      @include font-size(2.0);
      @include border-radius(50%);
    }
    .ct-iconBox-title {
      text-transform: uppercase;
      margin-bottom: 5px;
      font-family: $ff-montserrat;
      padding-bottom: 15px;
      @include font-size(1.4);
    }
    .ct-iconBox-description {
      color: #666;
      line-height: 24px;
      @include font-size(1.4);
    }
    &.ct-iconBox--white {
      .ct-iconBox-icon,
      .ct-iconBox-title,
      .ct-iconBox-description {
        color: #fff;
      }
    }
    &:hover {
      .ct-iconBox-icon {
        color: #fff;
      }
    }
  }
  // type 5
  &.ct-iconBox--type5 {
    background-color: transparent;
    text-align: center;
    padding: 72px 60px 60px;
    border: 1px solid rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, .5);
    @include t-all;
    @include border-radius(3px);
    .ct-iconBox-icon {
      color: #fff;
      @include t-all;
      @include font-size(5.0);
    }
    .btn {
      margin-top: 35px;
    }
    &:hover {
      @include mixin-background-color(0,
      0,
      0,
      .2);
      .ct-iconBox-icon {
        @include mix-transform(translateY(7px));
      }
    }
    &.ct-iconBox--variant {
      border-color: #dadada;
      .ct-iconBox-icon {
        color: #343434;
      }
      &:hover {
        background-color: #343434;
        .ct-iconBox-icon {
          color: #fff;
        }
      }
    }
  }
  // type small
  &.ct-iconBox--small {
    margin-top: 0;
    font-family: $ff-montserrat;
    a {
      display: block;
    }
    .media-body {
      width: auto;
    }
    .media-body,
    .media-left {
      vertical-align: middle;
    }
    .ct-iconBox-title {
      text-transform: uppercase;
      @include font-size(1.1);
      @include t-all;
    }
    .ct-iconBox-icon {
      display: inline-block;
      width: 37px;
      height: 37px;
      text-align: center;
      line-height: 37px;
      @include border-radius(50%);
      @include t-all;
    }
    &:hover,
    &:focus {
      .ct-iconBox-icon {
        color: #fff;
      }
    }
  }
}

.ct-iconBox--stepped {
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  @include md-max {
    .ct-iconBox {
      text-align: center;
      float: left;
      width: 50%;
      margin-bottom: 40px;
      @include md-max {
        .media-left {
          display: table;
          margin: 0 auto;
        }
        +.ct-iconBox {
          margin-top: 0;
        }
      }
      @include sm-max {
        float: none;
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  }
}

// ImageBox // ---------------------------------------------------------------------------------------------------------
.ct-imageBox {
  position: relative;
  z-index: 2;
  overflow: hidden;
  img {
    @include t-random {
      transition: all .4s ease-in-out;
    }
    ;
  }
  &:before {
    content: '';
    z-index: 1;
    background-color: transparent;
    background-color: rgba(0, 0, 0, .7);
    @include full-absolute;
  }
  figcaption {
    z-index: 3;
    @include full-absolute;
    .inner {
      display: table;
      width: 100%;
      height: 100%;
      text-align: center;
      .inner-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .ct-imageBox-icon {
      color: #fff;
      padding-bottom: 20px;
      line-height: 1;
      @include font-size(5.0);
      @include md-min {
        padding-bottom: 40px;
      }
    }
  }
  &:hover {
    img {
      @include mix-transform(scale(1.2) rotate(10deg));
    }
  }
}

.ct-imageBox--inline {
  color: #fff;
  @include font-size(1.4);
  figcaption {
    padding-left: 15px;
    max-width: 500px;
    .inner {
      text-align: left;
    }
  }
  &.ct-imageBox--left {
    figcaption {
      left: 15px;
      padding: 0;
      max-width: 400px;
    }
  }
  .inner-cell {
    &:first-child {
      padding-right: 10px;
    }
  }
  .ct-imageBox-content {
    padding-left: 10px;
  }
  @include xs-max {
    figcaption {
      .ct-imageBox-icon {
        @include font-size(2);
      }
    }
    .btn {
      padding: 5px;
    }
  }
  @include xs-min {
    @include font-size(1.8);
    figcaption {
      padding-left: 30px;
    }
    .inner-cell {
      &:first-child {
        padding-right: 30px;
      }
    }
    .ct-imageBox-content {
      padding-left: 30px;
    }
    &.ct-imageBox--left {
      figcaption {
        left: 30px;
      }
    }
  }
  @include lg-min {
    figcaption {
      width: 50%;
      padding-left: 100px;
    }
    &.ct-imageBox--left {
      figcaption {
        left: 50%;
        margin-left: -100px;
        padding: 0;
        max-width: 400px;
      }
    }
  }
}

// Section media // --------------------------------------------------------------------------------------------------------
.ct-section-media {
  .btn-block {
    max-width: 285px;
  }
}

.ct-section-media-col-2 {
  .ct-section-media-text-container {
    padding: 40px 0;
  }
  @include sm-min {
    display: table;
    width: 100%;
    .ct-section-media-row {
      display: table-row;
    }
    .ct-section-media-item {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding: 0;
    }
  }
  @include sm-max {
    .ct-section-media-row {
      display: flex;
      flex-direction: column;
      .ct-section-media-item {
        order: 1;
      }
      .ct-section-media-text-container {
        order: 2;
      }
    }
  }
}

.ct-container-mini {
  p {
    color: #666666;
    font-style: italic;
    @include font-size(1.8);
    margin-bottom: 20px;
  }
  @include sm-min {
    p {
      width: 30%;
      margin: 0 auto 40px auto;
    }
  }
}

.ct-section-media-text-container {
  @include sm-min {
    padding: 100px 0;
  }
}

// Page header // ------------------------------------------------------------------------------------------------------
.ct-pageHeader {
  &.ct-pageHeader--type1,
  &.ct-pageHeader--type2 {
    .ct-pageHeader-image {
      margin: 0 auto;
    }
    .ct-pageHeader-title {
      padding-top: 20px;
      font-weight: 300;
      @include font-size(7.2);
      @include sm-max {
        padding-top: 0;
        @include font-size(5.0);
      }
      @include xs-max {
        @include font-size(3.0);
      }
      span {
        display: block;
        padding-top: 15px;
        font-weight: 700;
        &.inline {
          display: inline;
        }
      }
    }
  }
  &.ct-pageHeader--type2 {
    .ct-pageHeader-title {
      padding-top: 18px;
    }
  }
  &.ct-pageHeader--type3 {
    .media {
      display: table;
      margin: 0 auto;
      .media-body {
        width: auto;
      }
    }
    .media-left {
      padding-right: 40px;
      img {
        max-width: none;
      }
    }
    .media-left,
    .media-body {
      vertical-align: middle;
    }
    .ct-pageHeader-title {
      margin-top: 0;
      font-weight: 300;
      text-align: left;
      @include font-size(7.2);
      @include sm-max {
        @include font-size(5.0);
      }
      @include xs-max {
        @include font-size(4.8);
      }
      span {
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 7px;
      }
    }
    @include xs-max {
      .media,
      .media-left,
      .media-body {
        display: block;
        padding: 0;
      }
      img {
        margin: 0 auto 20px auto;
      }
      .ct-pageHeader-title {
        text-align: center;
      }
    }
  }
  &.ct-pageHeader--type4 {
    margin-top: -40%;
    span {
      display: block;
      margin-top: 10px;
    }
    @include md-min {
      margin-top: -25%;
    }
    @media (min-width: 1600px) {
      margin-top: -30%;
    }
  }
}

// Divider section // --------------------------------------------------------------------------------------------------
.ct-dividerSection {
  .inner {
    .ct-dividerSection--right {
      text-align: center;
    }
  }
  @include sm-min {
    display: table;
    width: 100%;
    .inner {
      .ct-dividerSection--left,
      .ct-dividerSection--right {
        display: table-cell;
        vertical-align: middle;
      }
      .ct-dividerSection--left {
        width: 58.33333%;
      }
      .ct-dividerSection--right {
        width: 41.66667%;
        text-align: right;
      }
    }
  }
  &.ct-call-to-action--type1 {
    @include lg-max {
      .ct-dividerSection--left,
      .ct-dividerSection--right {
        display: block;
        width: 100%;
        text-align: center;
        .ct-iconBox--type3 {
          display: table;
          margin: 0 auto;
          .media-body {
            width: auto;
          }
        }
        .btn-group {
          margin-top: 15px;
        }
      }
    }
  }
  .ct-contactForm {
    button[type="submit"] {
      position: relative;
      border: none;
      background-color: transparent;
      padding: 0;
      transform: translateX(0);
    }
  }
  @include sm-min {
    .form-control,
    .selectize-control {
      width: 176px;
    }
    .form-inline {
      .form-control {
        width: auto;
      }
    }
  }
  &.ct-dividerSection--currency {
    .selectize-control {
      text-align: left;
    }
    .ct-dividerSection--left {
      width: 100%;
    }
    .ct-dividerSection--right {
      width: 100%;
    }
    .btn-group {
      margin-top: 0!important;
    }
    @include sm-min {
      .form-control,
      .selectize-control {
        width: 176px;
      }
    }
    @include lg-max {
      .ct-dividerSection--left,
      .ct-dividerSection--right {
        display: block;
        text-align: center;
      }
    }
    ;
    @include lg-min {
      .ct-contactForm {
        &.form-inline {
          .form-group {
            padding: 0 5px;
          }
        }
      }
      .ct-dividerSection--left {
        width: 45%;
      }
      .ct-dividerSection--right {
        width: 55%;
        text-align: right;
        .selectize-input {
          text-align: left;
        }
      }
    }
  }
}

// Image Box // --------------------------------------------------------------------------------------------------------
.ct-personBox {
  display: table;
  margin: 0 auto;
  font-family: $ff-montserrat;
  .ct-personBox-image {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  .inner {
    @include mix-transform(scale(0));
    @include t-all;
    @include full-absolute;
    .inner-table {
      display: table;
      width: 100%;
      height: 100%;
      text-align: center;
      .inner-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .ct-personBox-nameHidden {
    color: #fff;
    text-transform: uppercase;
    @include font-size(2.0);
  }
  .ct-personBox-name {
    line-height: 36px;
    text-transform: uppercase;
    margin: -5px 0 0;
    padding-top: 8px;
    text-align: left;
    @include font-size(1.4);
    @include t-all;
  }
  &:hover {
    .inner {
      @include mix-transform(scale(1));
    }
  }
  +.ct-personBox {
    margin-top: 20px;
  }
  &.ct-personBox--special {
    .inner {
      @include mix-transform(scale(1));
    }
    .ct-personBox-nameHidden {
      @include font-size(5.0);
    }
  }
}

// Single testimonial // -----------------------------------------------------------------------------------------------
.ct-testimonial-slider {
  position: static;
}

.ct-testimonial {
  text-align: center;
  .ct-testimonial-icon {
    width: 170px;
    height: 170px;
    background-color: $motive;
    text-align: center;
    line-height: 170px;
    margin: 0 auto;
    color: #fff;
    @include font-size(8.0);
    @include border-radius(50%);
  }
  .ct-testimonial-text {
    color: #fff;
    font-family: $ff-libre-baskerville;
    font-style: italic;
    padding-top: 55px;
    @include font-size(3.0);
  }
}

.ct-testimonial-thumbnailItem {
  text-align: center;
  display: block;
  cursor: pointer;
  margin-top: 50px;
  .ct-testimonial-author {
    font-family: $ff-montserrat;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 4px;
    @include font-size(1.4);
  }
  .ct-testimonial-authorDescription {
    color: #fff;
    text-transform: uppercase;
  }
}

.ct-testimonials-slider-nav-thumbnails {
  .slick-slide:not(.slick-current) {
    .ct-testimonial-thumbnailItem {
      @include opacity(.2);
    }
  }
}

.ct-testimonial-small {
  .ct-testimonial-text {
    color: #8c8c8c;
  }
  .ct-testimonial-author {
    display: block;
    font-style: italic;
    @include font-size(1.8);
    color: #666666;
    margin-top: 30px;
  }
  .ct-testimonial-icon {
    height: 86px;
    width: 86px;
    line-height: 86px;
    @include font-size(4.0);
  }
  @include lg-min {
    width: 80%;
    margin: 0 auto;
  }
  @include lg-max {
    padding: 0 20px;
    .ct-testimonial-text {
      padding-top: 30px;
      @include font-size(1.8);
    }
  }
}

// Progress bar // -----------------------------------------------------------------------------------------------------
.ct-progressBar {
  font-family: $ff-montserrat;
  margin-bottom: 48px;
  .ct-progressBar-title {
    text-transform: uppercase;
    padding-bottom: 5px;
    @include font-size(1.1);
  }
  .progress {
    position: relative;
    background-color: #e6e6e6;
    height: 8px;
    overflow: visible;
    margin: 0;
    @include border-radius(25px);
    @include mix-box-shadow-reset;
    .progress-bar {
      @include border-radius(25px);
    }
    .ct-progressBar-text {
      position: absolute;
      right: 0;
      bottom: 100%;
      line-height: 1;
      padding-bottom: 8px;
      @include font-size(1.1);
    }
  }
}

// Google maps // ------------------------------------------------------------------------------------------------------
.ct-googleMap {
  .ct-mapBox {
    .ct-mapBox-image {
      position: relative;
      background-color: #fff;
      width: 50px;
      height: 50px;
      display: block;
      text-align: center;
      line-height: 50px;
      @include mix-box-shadow(2px,
      4px,
      5px,
      rgba(0,
      0,
      0,
      0.3),
      $inset: false);
      @include border-radius(50%);
      &:before {
        content: '';
        position: absolute;
        @include triangle(bottom,
        15px,
        8px,
        #fff);
        bottom: -7px;
        left: 50%;
        margin-left: -7px;
      }
    }
  }
}

// Address // ----------------------------------------------------------------------------------------------------------
.ct-address {
  margin: 0;
  .inner {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
  .ct-address-item {
    display: block;
    text-align: left;
    padding: 35px;
    @include lg-max {
      padding: 30px;
    }
    &:hover {
      .ct-widget-icon {
        i {
          -webkit-animation-name: hvr-buzz-out;
          animation-name: hvr-buzz-out;
          -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
          -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
          -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
        }
      }
    }
    .ct-widget {
      display: table;
      margin: 0 auto;
    }
    .ct-widget-icon {
      color: #fff;
      float: left;
      @include font-size(5.0);
    }
    .ct-widget-content {
      float: left;
      padding-left: 22px;
      @include lg-max {
        padding-left: 15px;
      }
    }
    .ct-widget-title {
      font-weight: 700;
      text-transform: uppercase;
      font-style: italic;
      color: #fff;
      line-height: 30px;
      margin-bottom: 0;
      @include font-size(2.4);
      @include lg-max {
        @include font-size(2.0);
      }
    }
    .ct-widget-description {
      color: #fff;
      text-transform: uppercase;
      font-family: $ff-montserrat;
      margin-bottom: 0;
    }
  }
  // variant with 3 items
  &.ct-address--col3 {
    .ct-address-item {
      float: left;
      width: 33.333333%;
      @include md-max {
        width: 50%;
        &:nth-child(3) {
          margin-left: 25%;
        }
      }
      @include sm-max {
        width: 100%;
        float: none;
        &:nth-child(3) {
          margin-left: 0;
        }
      }
    }
  }
  // variant with 2 items
  &.ct-address--col2 {
    .ct-address-item {
      float: left;
      width: 50%;
      @include sm-max {
        width: 100%;
        float: none;
      }
    }
  }
  //variant with 1 item
  &.ct-address-col1 {
    .ct-address-item {
      width: 100%;
      float: none;
    }
  }
  // address footer
  .ct-address--footer {
    text-align: center;
    text-transform: uppercase;
    font-family: $ff-montserrat;
    color: #fff;
    background-color: #343434;
    padding: 12px 50px;
    i {
      padding-right: 10px;
    }
  }
}

// Divided section // --------------------------------------------------------------------------------------------------
.ct-dividedSection {
  display: table;
  width: 100%;
  .inner {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    .inner-text {
      max-width: 470px;
      margin-left: 100px;
      @include lg-max {
        max-width: none;
        padding-left: 25px;
        padding-right: 25px;
        margin-left: 0;
        .btn-group {
          margin-top: 40px!important;
        }
      }
      @include md-max {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 35px;
      }
    }
    @include md-max {
      display: block;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

html.browser-mozilla {
  .ct-dividedSection {
    display: flex;
    align-items: center;
    justify-content: center;
    @include md-max {
      display: block;
    }
    .inner {
      display: block;
      @include md-max {
        display: block;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}

// Button scroll to next section // ------------------------------------------------------------------------------------
.ct-nextSection-scroll {
  position: absolute;
  left: 50%;
  margin-left: -30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  z-index: 9;
  bottom: 0px;
  cursor: pointer;
  i {
    @include font-size(3.0);
    @include t-all;
  }
  span {
    display: block;
    padding-top: 5px;
    @include font-size(1.2);
    @include t-all;
  }
  &.ct-nextSection-scroll--motive {
    span {
      color: #191919;
      font-family: $ff-montserrat;
    }
    &:hover,
    &:focus {
      i {
        color: #191919;
      }
    }
  }
  &.ct-nextSection-scroll-dark {
    color: #191919;
  }
  @include sm-min {
    bottom: 30px;
  }
}

// Contact Box // ------------------------------------------------------------------------------------------------------
.ct-contactBox {
  a {
    position: relative;
    display: inline-block;
    .ct-contactBox-top {
      position: absolute;
      top: -17px;
      font-weight: 700;
      font-style: italic;
      color: #ccc;
      text-transform: uppercase;
      @include font-size(1.2);
      @include t-all;
    }
    .ct-contactBox-number {
      font-weight: 700;
      font-style: italic;
      margin: 0;
      @include font-size(2.4);
      @include t-all;
    }
    &:hover,
    &:focus {
      .ct-contactBox-number {
        color: #ccc!important;
      }
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

// Search link // ------------------------------------------------------------------------------------------------------
.ct-search-link {
  a {
    display: inline-block;
    text-align: center;
    width: 37px;
    height: 37px;
    line-height: 37px;
    @include border-radius(50%);
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

// Product preview // --------------------------------------------------------------------------------------------------
.ct-productPreview {
  .ct-iconBox {
    position: relative;
    .ct-iconBox-decorativeLine {
      content: '';
      position: absolute;
      top: 50%;
      height: 1px;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 5px;
        height: 5px;
        margin-top: -2.5px;
        margin-left: -20px;
        @include border-radius(50%);
      }
      @include md-max {
        display: none;
      }
    }
    &.ct-iconBox-decorativeLine--Left {
      .media-left {
        width: 100%;
      }
      .ct-iconBox-decorativeLine {
        &:after {
          left: 0;
          right: auto;
          margin-left: 0;
        }
      }
    }
  }
  @include sm-max {
    .ct-productPreview-image {
      width: 50%;
      margin: 0 auto;
    }
  }
  @include md-max {
    .ct-iconBox--stepped {
      padding: 50px 0!important;
    }
  }
}

// Divider lines // ----------------------------------------------------------------------------------------------------
.ct-divider-line {
  display: block;
  height: 1px;
  border: 0 none;
  margin: 0;
  @include mixin-background-color(255,
  255,
  255,
  .1);
}

.ct-divider-line--type2 {
  position: relative;
  height: 1px;
  background-color: #51bf9f;
  &:before,
  &:after {
    content: '';
  }
  &:before,
  &:after,
  .ct-divider-line-inner {
    position: absolute;
    background-color: #fff;
    height: 10px;
    width: 10px;
    top: 50%;
    margin-top: -5px;
    border-radius: 50%;
    border: 2px solid #51bf9f;
  }
  .ct-divider-line-inner {
    left: 50%;
    margin-left: -5px;
  }
  &:before {
    left: 105px;
  }
  &:after {
    right: 105px;
  }
  @include md-min {
    &:before {
      left: 60px;
    }
    &:after {
      right: 60px;
    }
  }
  @include lg-min {
    &:before {
      left: 75px;
    }
    &:after {
      right: 75px;
    }
  }
}

.ct-divider-line-type3 {
  position: relative;
  .ct-divider-icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    margin: 0 0 0 auto;
    @include font-size(3.0);
  }
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: -20%;
    background-color: #fff;
  }
  &:before {
    content: '';
    height: 1px;
    right: 70px;
  }
  &:after {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-top: -3px;
  }
  span {
    text-transform: uppercase;
    font-style: italic;
    position: absolute;
    top: 12px;
    right: 75px;
    @include font-size(1.1);
  }
}

// Media box // --------------------------------------------------------------------------------------------------------
.ct-embed .ct-mediaBox-poster {
  position: absolute;
  top: 5.7%;
  left: 12.8%;
  z-index: 1;
  width: 855px;
  height: 534px;
  @include lg-max {
    width: 705px;
    height: 452px;
  }
  @include md-max {
    width: 540px;
    height: 338px;
  }
}

.ct-embed {
  .embed-responsive-item {
    background-color: #000;
    @extend .ct-mediaBox-poster;
  }
  .ct-playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    @include mix-transform(translate(-50%,
    -50%));
  }
}

.ct-mediaBox {
  @include sm-max {
    .embed-responsive-16by9 {
      padding-bottom: 0;
      width: 420px;
      height: 238px;
      margin: 0 auto;
      text-align: center;
    }
    img {
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
    }
    .ct-mediaBox-poster {
      width: 316px;
      height: 199px;
      left: 12.7%;
      top: 5%;
    }
  }
  @include xs-max {
    .embed-responsive-16by9 {
      padding-bottom: 0;
      width: 280px;
      height: 160px;
      margin: 0 auto;
      text-align: center;
    }
    img {
      max-width: 260px;
    }
    .ct-mediaBox-poster {
      width: 196px;
      height: 126px;
      left: 14.9%;
      top: 5%;
    }
  }
}

// Responsive social icons // ------------------------------------------------------------------------------------------
.ct-socials--responsive {
  position: fixed;
  top: 50%;
  left: 5px;
  z-index: 9999;
  @include mix-transform(translate(-150%,
  -50%));
  @include t-all;
  li {
    a {
      position: relative;
      display: block;
      width: 45px;
      height: 45px;
      background-color: #333;
      color: #fff;
      text-align: center;
      line-height: 45px;
      margin-bottom: 4px;
      &:before {
        content: '';
        position: absolute;
        left: -5px;
        top: 0;
        bottom: 0;
        width: 5px;
      }
    }
  }
  @include lg-max {
    display: block;
  }
  @media (max-width: 1300px) {
    @include mix-transform(translate(0%,
    -50%));
  }
  @include md-max {
    display: none;
  }
}

// Preloader // --------------------------------------------------------------------------------------------------------
.ct-preloader {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #fff;
  .ct-preloader-inner {
    display: table;
    text-align: center;
    width: 100%;
    height: 100%;
    .ct-preloader-logo {
      position: relative;
      top: -45px;
      display: table-cell;
      vertical-align: middle;
    }
    .ct-preloader-title {
      font-style: italic;
      margin: 25px 0 10px 0;
      @include font-size(1.0);
    }
  }
  .loading {
    position: absolute;
    width: 100%;
    height: 1px;
    margin: 20px auto;
    left: 0;
    right: 0;
  }
  .loading-line {
    position: absolute;
    background: #eee;
    width: 100%;
    height: 2px;
  }
  .loading-break {
    position: absolute;
    width: 15px;
    height: 2px;
  }
  .loading-dot-1 {
    -webkit-animation: loading 2s infinite;
    -moz-animation: loading 2s infinite;
    -ms-animation: loading 2s infinite;
    -o-animation: loading 2s infinite;
    animation: loading 2s infinite;
  }
  .loading-dot-2 {
    -webkit-animation: loading 2s 0.5s infinite;
    -moz-animation: loading 2s 0.5s infinite;
    -ms-animation: loading 2s 0.5s infinite;
    -o-animation: loading 2s 0.5s infinite;
    animation: loading 2s 0.5s infinite;
  }
  .loading-dot-3 {
    -webkit-animation: loading 2s 1s infinite;
    -moz-animation: loading 2s 1s infinite;
    -ms-animation: loading 2s 1s infinite;
    -o-animation: loading 2s 1s infinite;
    animation: loading 2s 1s infinite;
  }
  @keyframes loading {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  @-moz-keyframes loading {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  @-webkit-keyframes loading {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  @-ms-keyframes loading {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
  @-o-keyframes loading {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
}

// List // -------------------------------------------------------------------------------------------------
.ct-list-decorated {
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding: 12px 0 12px 15px;
    border-bottom: 1px solid #d9d9d9;
    &:before {
      content: '\f0da';
      font-family: fontawesome;
      font-size: 10px;
      position: absolute;
      left: 0;
      top: 15px;
    }
  }
  a {
    display: block;
  }
}

// Description list // -------------------------------------------------------------------------------------------------
.ct-descriptionList {
  counter-reset: descList;
  dt {
    &:before {
      counter-increment: descList;
      content: counter(descList) ". ";
    }
  }
  dt {
    padding-top: 80px;
    @extend .h4;
    @include sm-max {
      padding-top: 40px;
    }
  }
  dd {
    color: #666;
    padding-top: 25px;
    @extend .h4;
  }
}

// Key Frames // -------------------------------------------------------------------------------------------------------

/* Buzz Out */

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg);
  }
}

@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0deg);
    transform: translateX(1px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0deg);
    transform: translateX(-1px) rotate(0deg);
  }
}

// Moving img [vertical] // --------------------------------------------------------------------------------------------
.ct-animationFloating--vertical {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: floating 10s -1s infinite;
  animation: floating 10s -1s infinite;
}

.ct-animationFloating--horizontal {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation: floating-horizontal 10s -1s infinite;
  animation: floating-horizontal 10s -1s infinite;
}

@keyframes floating {
  0%,
  100% {
    @include mix-transform(translateY(-5%));
  }
  50% {
    @include mix-transform(translateY(0));
  }
}

@keyframes floating-horizontal {
  0%,
  100% {
    @include mix-transform(translate(-5%,
    -5%));
  }
  25% {
    @include mix-transform(translate(5%,
    5%));
  }
  50% {
    @include mix-transform(translate(5%,
    -5%));
  }
  75% {
    @include mix-transform(translate(-5%,
    5%));
  }
}

// 404 // --------------------------------------------------------------------------------------------
.not_found {
  .ct-sectionHeader-subtitle {
    display: block;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 40px auto;
  }
  .btn-group {
    margin-bottom: 40px;
  }
  @include sm-min {
    .ct-sectionHeader-title {
      @include font-size(7.2);
      span {
        @include font-size(17.2);
      }
    }
    p {
      width: 50%;
    }
    .ct-searchForm--default {
      margin-top: 40px;
    }
  }
  .ct-sectionHeader .ct-sectionHeader-title {
    color: #fff;
    text-transform: uppercase;
    span {
      color: #fff;
    }
  }
}

// Vertical Navigation // --------------------------------------------------------------------------------------------
.ct-navigation-vertical {
  position: fixed;
  display: none;
  z-index: 100;
  top: 50%;
  right: 20px;
  margin-top: -55px;
  ul {
    list-style: none;
    >li {
      >a {
        padding: 0;
        &:focus {
          background-color: transparent;
        }
      }
      &:hover {
        >a {
          background-color: #b6becd;
        }
      }
      &.active {
        >a {
          background-color: #b6becd;
        }
      }
    }
  }
  a {
    display: block;
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 2px solid #b6becd;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  @include sm-min {
    display: block;
  }
  @include lg-min {
    right: 40px;
  }
}

// Shop
.ct-cart {
  font-family: $ff-montserrat;
  position: fixed;
  top: 55px;
  z-index: 4000;
  right: -200px;
  background-color: #fff;
  width: 200px;
  @include t-all;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  ol {
    list-style: none;
    padding-left: 0;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  &__inner {
    position: relative;
  }
  &__product {
    background-color: #fff;
    padding: 10px 10px 20px 10px;
    position: relative;
    z-index: 2;
    min-height: 150px;
  }
  &__product-total {
    display: block;
    margin-bottom: 12px;
    text-align: right;
    &:before {
      content: "Total: ";
      float: left;
    }
  }
  &__product-name {
    display: block;
  }
  &__product-left,
  &__product-body {
    float: left;
  }
  &__product-left {
    width: 40px;
  }
  &__product-body {
    width: calc(100% - 40px);
    padding-left: 10px;
  }
  &__product-price,
  &__product-input {
    font-family: $ff-open-sans;
  }
  &__product-input {
    width: 40px;
    margin-left: 10px;
  }
  &__product-checkout,
  &__product-clear {
    @extend .btn;
    @extend .btn-xs;
    box-shadow: none;
    border: none;
  }
  &__product-clear {
    color: #fff;
    background-color: #ee3e53;
    margin-right: 10px;
    &:hover {
      background-color: #f26d7d;
    }
  }
  &__product-checkout {
    @extend .btn-accient;
  }
  &__product-remove,
  &__product-checkout {
    float: right;
  }
  &__product-remove {
    color: #fff;
    border-radius: 50px;
    background-color: #ee3e53;
    border: 2px solid #ee3e53;
    &:hover {
      background-color: #f26d7d;
      border: 2px solid #f26d7d;
    }
  }
  &__message {
    text-align: center;
    text-transform: uppercase;
    height: 40px;
    width: 40px;
    font-size: 14px;
    line-height: 40px;
    padding: 0 10px;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3bf295;
    @include t-all();
  }
  &__message-added {
    left: -40px;
  }
  &__product-empty {
    text-align: center;
    @include font-size(2.0);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -14px;
  }
  &__button {
    display: none;
  }
  @include sm-min {
    top: 30%;
    &__button {
      display: block;
      position: absolute;
      background-color: #fff;
      text-align: center;
      cursor: pointer;
      height: 40px;
      width: 40px;
      top: 0;
      left: -40px;
      @include font-size(2.0);
      line-height: 40px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
    &__message-added {
      left: -80px;
    }
  }
  @include md-min {
    width: 250px;
    right: -250px;
    &__product {
      padding: 10px 20px 20px 20px;
    }
  }
}

.cart-is-open {
  .ct-cart {
    right: 0;
  }
  .navbar {
    .ct-cart {
      display: none;
    }
  }
}

.navbar {
  .ct-cart {
    color: #000;
    position: absolute;
    top: 50px;
    // left: 0;
    right: -25px;
    &__button {
      display: none;
    }
    ol {
      margin-bottom: 10px;
    }
    &__message-added {
      left: -40px;
    }
  }
}
