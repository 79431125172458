.btn {
  padding: 13px 18px 11px 18px;
  font-family: $ff-montserrat;
  text-transform: uppercase;
  @include font-size(1.1);
  @include border-radius(50px);
  &.btn-xs {
    padding: 8px 12px 6px 12px;
  }
  &.btn-sm {
    padding: 10px 15px 9px 15px;
  }
  &.btn-lg {
    padding: 16px 26px 13px 26px;
  }
}

.btn-group.btn-group--separated .btn.btn-lg:last-child:not(.dropdown-toggle) {
  padding: 21px 18px 21px 15px;
}
.btn-group--marginRight-0 {
  .btn:last-child {
    margin-right: 0!important;
  }
}
.btn-group.btn-group--separated {
  .btn-lg {
    @include font-size(1.4);
    padding: 21px 0 21px 0;
    @include md-max {
      padding: 21px 25px 21px 20px;
    }
  }
  .btn-lg{
    &:first-child{
      padding: 20px 30px;
    }
    &:last-child{
      padding: 20px 18px 20px 15px!important;
    }
  }
}

.ct-link {
  line-height: 30px;
  @include font-size(1.1);
  @include mixin-color-rgba(255,255,255,.5);
  &:after {
    padding-left: 8px;
    content: '\f0da';
    font-family: 'FontAwesome';
  }
}

// Button accient

.btn-accient {
  color: #fff;
  &:hover, &:focus {
    color: #fff;
  }
  &:active {
    color: #fff;
    @include mix-box-shadow(0, 1px, 10px, rgba(0, 0, 0, 0.5), true);
  }
}

// Button dark

.btn-dark {
  background-color: #333;
  color: #fff;
  &:hover, &:focus, &:active {
    color: #fff;
    background-color: #000;
  }
}


// Button motive

.btn.btn--motiveColor {
  color: #fff;
  &.btn-transparent {
    &:hover, &:focus {
      color: #fff;
    }
  }
}


// Button white

.btn-white {
  background-color: #fff;
  color: #333;
}

// Button transparent

.btn.btn-transparent {
  background-color: transparent;
  &:active {
    @include mix-box-shadow(0, 1px, 10px, rgba(0, 0, 0, 0.5), true);
  }
}


.btn.btn-transparent.btn--white {
  border: 1px solid #fff;
  color: #fff;
  &:hover, &:focus {
    background-color: #fff;
  }
}

// Buttons with icon

.btn {
  &.btn--withIcon {
    padding-right: 21px;
    i {
      padding-left: 10px;
    }
  }
}

// Button groups // ----------------------------------------------------------------------------------------------------

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.btn-group>.btn:last-child:not(:first-child), .btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.btn-group {
  .btn {
    margin: auto 10px;
  }
  &.btn-group--alignLeft {
    .btn {
      &:first-child {
        margin-left: 0!important;
      }
      &:last-child {
        margin-right: 0!important;
      }
    }
  }
  @include xs-max {
    .btn{
      margin-bottom: 10px;
    }
  }
}

// Buttons separated // ------------------------------------------------------------------------------------------------

.btn-group.btn-group--separated {
  margin: 0 13px;
  .btn {
    margin: 0!important;
    @include t-all;
  }
  .btn:first-child:not(:last-child):not(.dropdown-toggle) {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0;
   }
  .btn:last-child:not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  &:hover {
    .btn {
      color: #fff;
    }
    .btn:last-child {
      @include mix-transform(translateX(-3px));
    }
  }
  &:hover {
    .btn.btn-white {
      color: #333;
    }
  }
  .btn-dark {
    &:hover, &:active, &:focus {
      background-color: #333;
    }
  }
  .btn-accient:nth-child(2){
      border-left: 2px solid transparent;
  }
}


// Button download // --------------------------------------------------------------------------------------------------

.ct-file-size {
  font-family: $ff-montserrat;
  display: block;
  text-transform: uppercase;
  padding-top: 15px;
  @include opacity(.5);
  @include font-size(1.1);
}

.btn-group.btn-group--separated.btn-block {
  margin-left: 0;
  margin-right: 0;
  @include md-max {
    width: auto;
  }
  .btn:first-child {
    width: 80%;
    @include md-max {
      width: auto;
    }
  }
}

.btn-group, .btn-group-vertical {
  > .btn{
    @include xs-max {
      float: none;
    }
  }
}

form.btn{
  padding: 0;
}

.btn-separated.paypal_btn{
  border-radius: 50px 0 0 50px;
}

.ct-dividerSection{
  form.btn{
    position: relative;
    .paypal_btn{
      position: relative;
      right: -5px;
    }
  }
  @include xs-min {
    form.btn{
      top: -1px;
    }
  }
}

.navbar{
  .paypal_btn{
    position: relative;
    right: -3px;
  }
}
