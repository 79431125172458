$dropdown-bg: #343434;

.ct-nav {
  position: relative;
  z-index: 999;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  @include t-random {
    transition: all .4s linear;
  };
}
.navbar-default {
  margin: 0;
  font-family: $ff-montserrat;
  @include border-radius(0);
  .navbar-brand {
    display: block;
    padding: 19px 0;
    margin-left: 0!important;
  }
  .navbar-nav {
    margin-right: -30px;
    @include md-max {
      margin-right: -20px;
    }
  }
}


.navbar-default {
  background-color: #fff;
  .navbar-header{
    width: 20%;
    @include md-min {
      width: auto;
    }
  }
  .navbar-nav {
    float: right;
    > li {
      padding: 0;
      &.dropdown {
        &:after {
          content: '\f107';
          font-family: "FontAwesome";
          position: absolute;
          top: 50%;
          margin-top: -2px;
          margin-left: -4px;
          left: 50%;
          @include md-min {
            right: 5px;
            margin-top: -9px;
            margin-left: 0;
            left: auto;
          }
          @include lg-min {
            right: 15px;
          }
        }
      }
      > a {
        position: relative;
        overflow: hidden;
        text-transform: uppercase;
        color: #333;
        padding: 30px 10px;
        @include border-radius(50px);
        @include font-size(1.1);
        // @include md-max {
        //   padding: 30px 20px;
        // }
        @include md-min {
          padding: 30px 20px;
        }
        @include lg-min {
          padding: 30px;
        }
        &:before, &:after {
          content: '';
          position: absolute;
          display: block;
          left: 1px;
          right: 1px;
          height: 21px;
          @include opacity(0);
          @include t-random {
            transition: all .2s linear;
          };
        }
        &:before{
          top: -50%;
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
        }
        &:after {
          bottom: -50%;
          border-bottom: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom-left-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }
      &:hover {
        a {
          &:before, &:after {
            @include opacity(1);
          }
          &:before{
            top: 20px;
          }
          &:after {
            bottom: 20px;
          }
        }
      }
      &.active {
        a {
          background-color: transparent;
          &:hover, &:focus, &:active {
            background-color: transparent;
          }
        }
      }
    }
  }
  .navbar-buttons {
    .btn-group.btn-group--separated {
      margin: 24px 0 0 60px;
    }
  }
}

.navbar-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.navbar-scrolled .navbar--animated {
  position: fixed;
  &.animated-init {
    -webkit-animation-name: navbar-animation;
    animation-name: navbar-animation;
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
}

.navbar-scrolled .navbar.navbar-dark {
  background-color: #000;
}
body.navbar-scrolled.ct-menu-effect .navbar-beacon {
  background-color: #000;
  li{
    a{
      &:hover{
        background-color: #fff;
        color: #000;
      }
    }
    &.dropdown{
      .dropdown-menu{
        border-bottom: 1px solid #fff;
      }
    }
  }
}

@-webkit-keyframes navbar-animation {
  0% {
    top: -100%; }
  70% {
    top: -5%; }
  100% {
    top: 0; } }

@keyframes navbar-animation {
  0% {
    top: -100%; }
  70% {
    top: -5%; }
  100% {
    top: 0; } }

// Navbar desktop dropdown // ------------------------------------------------------------------------------------------

.navbar-default {
  .navbar-nav {
    .dropdown-menu {
      background-color: $dropdown-bg;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0 none;
      min-width: 200px;
      margin: 0;
      padding: 0;
      margin-left: -25px;
      @include border-radius(0);
      li {
        a {
          position: relative;
          color: #fff;
          text-transform: uppercase;
          line-height: 40px;
          padding: 0 29px;
          @include font-size(1.1);
        }
        &:hover, &:active, &:focus {
          > a {
            @include mixin-background-color(26,26,26,.5);
            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 3px;
            }
          }
        }
        &.dropdown-submenu {
          position: relative;
          &:after {
            content: '\f105';
            position: absolute;
            right: 15px;
            top: 50%;
            font-family: "FontAwesome";
            color: #fff;
            @include mix-transform(translateY(-50%));
          }
          .dropdown-menu {
            top: 0;
            left: 100%;
            margin: 0;
            > li {
              > a {
                &:before{
                  left: auto;
                  right: 0;
                }
              }
            }
          }
          &:hover {
            .dropdown-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}


@include sm-max {
  .navbar-desktop {
    display: none;
  }
}

// Navbar desktop effects // -------------------------------------------------------------------------------------------

.ct-navbar--bounceIn > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--fadeIn > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--fadeInLeft > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--fadeInRight > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--fadeInDown > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--fadeInUp > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--pulse > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--bounceInRight > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--bounceInLeft > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--flipInX > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--flipInY > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--zoomIn > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--zoomInDown > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.ct-navbar--zoomInUp > li.dropdown:hover .dropdown-menu {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}


// Navbar dark // ------------------------------------------------------------------------------------------------------

.navbar {
  &.navbar-dark {
    margin: 0;
    padding-top: 20px;
    background-color: #000;
    @include md-min {
      @include mixin-background-color(0,0,0,.5);
    }
    .navbar-header {
      float: none;
      padding: 0;
      text-align: center;
      .navbar-brand {
        float: none;
        margin: 0;
        height: auto;
        padding: 0;
        display: inline-block;
        img {
          margin: 0 auto;
        }
      }
    }
    .nav.navbar-nav {
      float: right;
      margin-right: -40px;
      li {
        a {
          padding: 0;
          margin: 10px 40px 10px 0;
          color: #fff;
          cursor: pointer;
          &:hover , &:focus, &:active{
            background-color: transparent;
          }
        }
      }
    }
    .ct-productCart-link {
      position: relative;
    }
    .ct-productCart {
      display: none;
      position: absolute;
      min-height: 49px;
      top: 100%;
      right: 0;
      left: auto;
      min-width: 300px;
      padding: 15px;
      margin-top: 19px;
      @include border-radius(5px);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include mixin-background-color(0,0,0,.5);
      .ct-product-item {
        margin: 0;
        padding-bottom: 15px;
        .ct-product-image {
          @include border-radius(5px);
          img {
            max-width: none;
          }
        }
        .ct-product-details {
          padding-left: 15px;
          padding-top: 10px;
          position: relative;
          .ct-product-name {
            font-weight: 700;
            margin-bottom: 0;
            color: #fff;
          }
          .ct-product-price {
            color: #fff;
            font-style: italic;
          }
          .ct-product-addons {
            position: absolute;
            top: 0;
            right: 0;
            a {
              margin: 0;
              display: block;
              width: 20px;
              height: 20px;
              float: left;
              background-color: #000;
              color: #fff;
              text-align: center;
              line-height: 20px;
              @include opacity(.5);
              @include font-size(1.0);
              @include border-radius(5px);
              &.ct-product-remove {
                margin-right: 5px;
              }
              &:hover, &:focus {
                @include opacity(1);
              }
            }
          }
        }
      }
      .ct-product-no-items{
        color: #ccc;
        font-style: italic;
        display: none;
      }
      &:nth-last-child(1) {
        padding-bottom: 0;
      }
    }
  }
}

body.ct-menu-effect {
  .navbar-beacon {
    position: fixed;
    top: 80px;
    bottom: 0;
    right: 200%;
    height: 100%;
    max-width: 400px;
    z-index: 9000;
    overflow-y: auto;
    padding-right: 50px;
    background-color: #000;
    @include md-min {
      @include mixin-background-color(0,0,0,.5);
    }
    &.bounceInRight {
      width: 100%;
      left: auto;
      right: -50px;
    }
    &.bounceOutRight {
      width: 100%;
      left: auto;
      right: -50px;
    }
    &.bounceInRight-duration {
      -webkit-animation-duration: 0.75s;
      animation-duration: 0.75s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    li {
      a {
        display: block;
        color: #fff;
        text-align: center;
        font-family: $ff-montserrat;
        text-transform: uppercase;
        padding: 15px 0;
        @include font-size(1.4);
        &:hover{
          color: #000;
          background-color: #fff;
        }
        @include md-min {
          &:hover {
            color: #fff;
            @include mixin-background-color(0,0,0,.85);
          }
        }
      }
      &.is-active {
        > a {
          color: #000;
          background-color: #fff;
          @include md-min {
            color: #fff;
            @include mixin-background-color(0,0,0,.85);
          }
        }
      }
      &.dropdown {
        a {
          i {
            position: relative;
            float: right;
            right: 25px;
            @include t-all;
          }
        }
        .dropdown-menu {
          float: none;
          display: none;
          position: static;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border: 0 none;
          li {
            a {
              @include font-size(1.0);
            }
          }
        }
        &.is-active {
          a {
            i {
              @include mix-transform(rotate(180deg));
            }
          }
        }
      }
    }
    .ct-socials--rounded {
      text-align: center;
      margin-top: 65px;
      margin-right: -5px;
      li {
        a {
          padding: 0;
          &:hover, &:focus, &:active {
            border-color: #000;
          }
        }
      }
    }
  }
  .ct-menu-mobile + .navbar-beacon{
    top: 57px;
  }
}

// Navbar light // ------------------------------------------------------------------------------------------------------

.navbar{
  &.navbar-light{
    background-color: transparent;
    .navbar-nav{
      > li{
          padding: 25px 10px;
        > a{
          color: #fff;
          padding: 5px 0;
          border-bottom: 1px solid transparent;
          border-radius: 0;
          &:before, &:after{
            display: none;
          }
        }
        &.dropdown{
          &:after{
            color: #fff;
          }
        }
        &:hover{
          a{
            border-bottom-color: $motive;
          }
        }
        &.active{
          a{
            color: #fff;
          }
        }
      }
    }
    @include md-min {
      .navbar-nav{
        >li{
          padding: 25px 20px;
        }
      }
    }
    @include lg-min {
      .navbar-nav{
        >li{
          padding: 25px 30px;
        }
      }
    }
  }
}

.navbar-scrolled .navbar.navbar-light{
  background-color: #333;
}

// Navbar wide // ------------------------------------------------------------------------------------------------------

.navbar-wide {
  .navbar-header {
    line-height: 80px;
    .navbar-brand {
      position: relative;
      display: inline-block;
      margin-top: -4px;
      height: 80px;
      line-height: 80px;
      padding: 0 22px;
      @include lg-max {
        padding: 0 10px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 40px;
        top: 50%;
        right: 0;
        margin-top: -17px;
        background-color: #e5e5e5;
        @media (max-width: 1300px) {
          display: none;
        }
      }
      img {
        display: inline-block;
      }
    }
  }
  .ct-socials {
    position: relative;
    float: left;
    padding: 0 25px;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 40px;
      top: 50%;
      right: 0;
      margin-top: -20px;
      background-color: #e5e5e5;
    }
    @media (max-width: 1300px) {
      display: none;
    }
  }
  .ct-contactBox {
    float: left;
    padding: 0 25px;
    height: 80px;
    overflow: hidden;
    a {
      padding-top: 8px;
      .ct-contactBox-top {
        top: -10px;
      }
    }
    @media (max-width: 1800px) {
      display: none;
    }
  }
  .ct-search-link {
    float: right;
    height: 80px;
    line-height: 80px;
    padding: 0 22px;
    @include lg-max {
      padding: 0 10px;
    }
  }
  &.navbar-default {
    .navbar-nav {
      margin-right: 0;
    }
  }
  .ct-iconBox--small {
    position: relative;
    padding: 0 25px;
    float: left;
    @include lg-max {
      padding: 0 10px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 40px;
      top: 50%;
      left: 0;
      margin-top: -20px;
      background-color: #e5e5e5;
    }
    &.ct-contactBox--responsive {
      display: block;
     @media (max-width: 1799px) {
       display: block;
     }
      .media-left {
        padding-right: 0;
      }
    }
    @media (max-width: 1550px) {
      .media-body {
        display: none;
      }
    }
  }
  @media (max-width: 1550px) {
    .navbar-nav {
      > li {
        > a {
          padding-left: 15px;
          padding-right: 15px;
          @include lg-max {
            @include font-size(1);
          }
        }
        &.dropdown {
          &:after{
            left: 50%;
            right: auto;
            top: auto;
            bottom: 15px;
            @include mix-transform(translateX(-50%));
          }
        }
      }
    }
  }
  @include md-max {
    display: none;
  }
}

.navigation-wide{
  @include md-max {
    .ct-menu-mobile{
      display: block;
    }
    .ct-menu-mobile + .navbar-beacon {
      display: block;
    }
  }
  @include sm-min {
    .ct-menu-mobile{
      .ct-socials--rounded{
        padding: 9px 0;
      }
      .ct-productCart-link, .nav-item-toggle{
        padding: 8px 0;
      }
    }
  }
}

// Navbar mobile // ------------------------------------------------------------------------------------------------------

.ct-menu-mobile{
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  .navbar{
    padding-top: 0;
    .navbar-header{
      padding-top: 5px;
      text-align: left;
    }
    .ct-productCart{
      min-width: 200px;
    }
  }
  .nav{
    >li{
      display: inline-block;
    }
  }
  @include sm-min {
    display: none;
    & + .navbar-beacon{
      display: none;
      top: 57px;
    }
  }
}
@include sm-max {
  .navbar-beacon + .navbar-beacon{
    display: none;
  }
}
