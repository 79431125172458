@if $use-bounceInRight == true {

	@-webkit-keyframes bounceInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX($base-distance-big * 2);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateX(-$base-distance * 3);
	}

	80% {
		-webkit-transform: translateX($base-distance);
	}

	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes bounceInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX($base-distance-big * 2);
	}

	60% {
		opacity: 1;
		-moz-transform: translateX(-$base-distance * 3);
	}

	80% {
		-moz-transform: translateX($base-distance);
	}

	100% {
		-moz-transform: translateX(0);
	}
}

@-o-keyframes bounceInRight {
	0% {
		opacity: 0;
		-o-transform: translateX($base-distance-big * 2);
	}

	60% {
		opacity: 1;
		-o-transform: translateX(-$base-distance * 3);
	}

	80% {
		-o-transform: translateX($base-distance);
	}

	100% {
		-o-transform: translateX(0);
	}
}

@keyframes bounceInRight {
	0% {
		opacity: 0;
		transform: translateX($base-distance-big * 2);
	}

	60% {
		opacity: 1;
		transform: translateX(-$base-distance * 3);
	}

	80% {
		transform: translateX($base-distance);
	}

	100% {
		transform: translateX(0);
	}
}

.bounceInRight {
	@include animate-prefixer(animation-name, bounceInRight);
}
}
