$padding_margins: padding, margin;
$pos: top;

@media (max-width: $screen-sm-max){
  @each $padding_margin in $padding_margins {

    @each $size in $paddingmargin-sizes {

      // Vertical
      .ct-u-#{$padding_margin}-both-#{$size}-sm {
        #{$padding_margin}-top: $size + px !important;
        #{$padding_margin}-bottom: $size + px !important;
      }

      @each $position in $paddingmargin-positions {
        .ct-u-#{$padding_margin}-#{$position}-#{$size}-sm {
          #{$padding_margin}-#{$position}: $size + px !important;
        }
      }

    }

  }
}

@media (max-width: $screen-xs-max){
  @each $padding_margin in $padding_margins {

    @each $size in $paddingmargin-sizes {

      // Vertical
      .ct-u-#{$padding_margin}-both-#{$size}-xs {
        #{$padding_margin}-top: $size + px !important;
        #{$padding_margin}-bottom: $size + px !important;
      }

      @each $position in $paddingmargin-positions {
        .ct-u-#{$padding_margin}-#{$position}-#{$size}-xs {
          #{$padding_margin}-#{$position}: $size + px !important;
        }
      }

    }

  }
}
