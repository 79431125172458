@if $use-bounceOutRight == true {

	@-webkit-keyframes bounceOutRight {
		0% {
			-webkit-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-webkit-transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 0;
			-webkit-transform: translateX($base-distance-big * 2);
		}
	}

	@-moz-keyframes bounceOutRight {
		0% {
			-moz-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-moz-transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 0;
			-moz-transform: translateX($base-distance-big * 2);
		}
	}

	@-o-keyframes bounceOutRight {
		0% {
			-o-transform: translateX(0);
		}

		20% {
			opacity: 1;
			-o-transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 0;
			-o-transform: translateX($base-distance-big * 2);
		}
	}

	@keyframes bounceOutRight {
		0% {
			transform: translateX(0);
		}

		20% {
			opacity: 1;
			transform: translateX(-$base-distance * 2);
		}

		100% {
			opacity: 0;
			transform: translateX($base-distance-big * 2);
		}
	}

	.bounceOutRight {
		@include animate-prefixer(animation-name, bounceOutRight);
	}

}
