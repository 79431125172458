/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    &.ct-u-displayTable {
        .item {
            display: table;
            text-align: center;
            .item-inner {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
    .slick-track {
        cursor: e-resize;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        &:active {
            cursor: e-resize;
            cursor: -webkit-grabbing;
            cursor: -moz-grabbing;
        }
    }
}



html.browser-mozilla {
    .slick-slider {
        &.ct-u-displayTable {
            .item {
                display: flex;
                align-items: center;
                justify-content: center;
                .item-inner {
                    display: block;
                }
            }
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

// Slick dots // -------------------------------------------------------------------------------------------------------

.ct-dots--type1 {
    .slick-dots {
        li {
            width: 12px;
            height: 12px;
            button {
                width: 12px;
                height: 12px;
                border: 2px solid #fff;
                @include border-radius(50%);
                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-color: #fff;
                    top: 50%;
                    left: 50%;
                    margin-top: -1px;
                    margin-left: -1px;
                    @include opacity(0);
                    @include border-radius(50%);
                    @include t-all;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        @include opacity(1);
                    }
                    &:hover {
                        &:before {
                            width: 4px;
                            height: 4px;
                            top: 50%;
                            left: 50%;
                            margin-top: -1px;
                            margin-left: -1px;
                        }
                    }
                }
            }
        }
    }
}

    // Slick dots positions

.ct-dots--positionBottom {
    .slick-dots {
        bottom: 50px;
        width: 100%;
        @include sm-max {
            bottom: 15px;
        }
    }
}
.ct-dots--positionBottomCenter {
    .slick-dots {
        bottom: -10px;
        width: 100%;
    }
    @include md-min {
      .slick-dots {
          bottom: -50px;
      }
    }
}
.ct-dots--positionBottomOffset {
    .slick-dots {
        bottom: -200px;
        width: 100%;
    }
}
.ct-dots--positionRight {
    .slick-dots {
        top: 50%;
        right: 50px;
        bottom: auto;
        @include mix-transform(translateY(-50%));
        li {
            display: block;
            + li {
                margin-top: 10px;
            }
        }
    }
}
.ct-dots--positionLeft {
    .slick-dots {
        top: 50%;
        left: 50px;
        bottom: auto;
        @include mix-transform(translateY(-50%));
        li {
            display: block;
            + li {
                margin-top: 10px;
            }
        }
    }
}


// Slick arrows // -----------------------------------------------------------------------------------------------------

.ct-slick-arrows--rounded {
    .slick-arrow {
        color: $motive;
        width: 40px;
        height: 40px;
        display: block;
        @include border-radius(50%);
        @include t-all;
        &.slick-prev {
            left: 0;
            &:before {
                content: '\f104';
                font-family: "FontAwesome";
                color: #333;
                @include font-size(4.0);
                margin-left: -2px;
            }
        }
        &.slick-next {
            right: 0;
            &:before {
                content: '\f105';
                font-family: "FontAwesome";
                color: #333;
                margin-left: 1px;
                @include font-size(4.0);
            }
        }
        &.slick-prev, &.slick-next {
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                @include mix-transform(translate(-50%,-50%));
            }
            &.slick-next, &.slick-prev {
                &:before {
                    color: #fff;
                }
            }
        }
    }
    @include sm-min {
      .slick-arrow{
        width: 60px;
        height: 60px;
        @include opacity(0);
      }
      &.slick-prev{
        left: -100%;
      }
      &.slick-next{
        right: -100%;
      }
      &:hover {
          .slick-arrow {
              @include opacity(1);
              &.slick-next {
                  right: 25px;
              }
              &.slick-prev {
                  left: 25px;
              }
          }
      }
    }
}

.ct-slick-arrows--mobile-only{
  @include lg-min {
    .slick-prev, .slick-next{
      display: none!important;
    }
  }
}

.ct-dots--type1, .ct-slick-arrows--rounded{
  &.slick-slider{
    height: auto!important;
  }
}
