$motive: #4B71C5;
$motive-dark: darken($motive, 4%);
$motive-darker: darken($motive, 10%);
$accient: #4B71C5;
$accient-darker: darken($accient, 10%);
.ct-u-colorMotive {
  color: $motive;
}

// Copyright link text // ----------------------------------------------------------------------------------------------
.ct-copyright-text {
  a {
    color: $motive;
  }
}

// Links variants // ---------------------------------------------------------------------------------------------------
.ct-link {
  &.ct-link--arrowMotive {
    &:after {
      color: $motive;
    }
    &:hover,
    &:focus,
    &:active {
      color: $motive;
    }
  }
}

// Tooltips // ---------------------------------------------------------------------------------------------------------
.tooltip {
  .tooltip-inner {
    background-color: $motive;
  }
  &.top {
    .tooltip-arrow {
      border-top-color: $motive;
    }
  }
  &.right {
    .tooltip-arrow {
      border-right-color: $motive;
    }
  }
  &.bottom {
    .tooltip-arrow {
      border-bottom-color: $motive;
    }
  }
  &.left {
    .tooltip-arrow {
      border-left-color: $motive;
    }
  }
}

// Gallery // ----------------------------------------------------------------------------------------------------------
.ct-gallery {
  .ct-gallery-item {
    .ct-gallery-item-inner {
      background-color: rgba(red($motive), green($motive), blue($motive), .2);
    }
  }
  @include sm-min {
    .ct-gallery-item {
      .ct-gallery-item-inner {
        background-color: rgba(red($motive), green($motive), blue($motive), .8);
      }
    }
  }
}

// Icon Box // ---------------------------------------------------------------------------------------------------------
.ct-iconBox {
  &.ct-iconBox--type1 {
    .ct-iconBox-icon {
      border: 1px solid $motive;
    }
    &:hover {
      .ct-iconBox-icon {
        background-color: $motive;
      }
    }
  }
  &.ct-iconBox--type2 {
    .ct-iconBox-icon {
      border: 1px solid $motive;
    }
    &:hover {
      .ct-iconBox-icon {
        background-color: $motive;
      }
    }
  }
  &.ct-iconBox--type4 {
    .ct-iconBox-icon {
      border: 1px solid $motive;
    }
    &:hover {
      .ct-iconBox-icon {
        background-color: $motive;
      }
    }
  }
  &.ct-iconBox--small {
    .ct-iconBox-icon {
      border: 1px solid $motive;
    }
    a {
      &:hover,
      &:focus {
        .ct-iconBox-icon {
          background-color: $motive;
        }
        .ct-iconBox-title {
          color: $motive;
        }
      }
    }
  }
}

// Buttons // ----------------------------------------------------------------------------------------------------------
.btn--motiveColor {
  color: $motive;
  border: 1px solid $motive;
  background-color: $motive;
  &:hover,
  &:focus,
  &:active {
    background-color: $motive;
    color: #fff;
  }
  &.btn-transparent {
    color: $motive;
  }
}

.btn-accient {
  background-color: $accient;
  // border: 1px solid $accient;
  border: 0;
  &:hover,
  &:focus {
    background-color: $accient-darker;
    // border-color: $accient-darker;
  }
}

.btn.btn-transparent.btn--white {
  &:hover,
  &:focus {
    color: $motive;
  }
}

// Sections // ---------------------------------------------------------------------------------------------------------
.ct-dividerSection--motive {
  background: linear-gradient(to bottom right, #766CBD, #357BB3);
}

// Navbar // -----------------------------------------------------------------------------------------------------------
.navbar-default {
  border: 0 none;
  .navbar-nav {
    >li {
      padding: 0;
      >a {
        &:before,
        &:after {
          border-color: $motive;
        }
      }
      &.active {
        >a {
          color: $motive;
          &:hover,
          &:focus,
          &:active {
            color: $motive;
          }
        }
      }
      .dropdown-menu {
        li {
          &:hover,
          &:active,
          &:focus {
            >a {
              &:before {
                background-color: $motive;
              }
            }
          }
        }
        li.active {
          a {
            color: $motive;
          }
        }
      }
    }
  }
  &.navbar-light {
    .navbar-nav {
      >li {
        &:hover {
          a {
            border-bottom-color: $motive;
          }
        }
        &.active {
          a {
            border-bottom-color: $motive;
          }
        }
      }
    }
  }
}

// Breadcrumbs Header // -----------------------------------------------------------------------------------------------
.breadcrumb {
  li {
    a {
      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }
    +li {
      &:before {
        color: #fff;
      }
    }
  }
}

// Person box // -------------------------------------------------------------------------------------------------------
.ct-personBox {
  .inner {
    background-color: $motive;
  }
  &:hover {
    .ct-personBox-name {
      color: $motive;
    }
  }
}

// Progress bar // -----------------------------------------------------------------------------------------------------
.ct-progressBar {
  .progress {
    position: relative;
    background-color: #e6e6e6;
    height: 8px;
    overflow: visible;
    @include border-radius(25px);
    @include mix-box-shadow-reset;
    .progress-bar {
      background-color: $motive;
    }
    .ct-progressBar-text {
      color: $motive;
    }
  }
}

// Address // ----------------------------------------------------------------------------------------------------------
.ct-address {
  &.ct-address--descendingMotive {
    .ct-address-item {
      &:nth-child(1) {
        background-color: $motive-darker;
      }
      &:nth-child(2) {
        background-color: $motive-dark;
      }
      &:nth-child(3) {
        background-color: $motive;
      }
    }
  }
  &.ct-address--ascendingMotive {
    .ct-address-item {
      &:nth-child(1) {
        background-color: $motive;
      }
      &:nth-child(2) {
        background-color: $motive-dark;
      }
      &:nth-child(3) {
        background-color: $motive-darker;
      }
    }
  }
}

// Contact form // -----------------------------------------------------------------------------------------------------
.ct-contactForm {
  .form-control {
    &:focus {
      border-color: $motive;
    }
    &.is-not-empty {
      border-color: $motive;
    }
  }
  button[type="submit"] {
    background-color: $motive;
    &:hover {
      background-color: $motive-darker;
    }
  }
}

.ct-newsletter {
  .form-control {
    &:focus {
      border-color: $motive;
    }
    &.is-not-empty {
      border-color: $motive;
    }
  }
}

// Slick // ------------------------------------------------------------------------------------------------------------
.ct-dots--type1 {
  &.ct-dots--motive {
    li {
      button {
        border-color: $motive;
        &:before {
          background-color: $motive;
        }
      }
    }
  }
}

.ct-slick-arrows--rounded {
  .slick-arrow {
    &.slick-next,
    &.slick-prev {
      background-color: $motive;
      &:hover {
        background-color: $motive-darker;
      }
    }
  }
}

// Preloader // --------------------------------------------------------------------------------------------------------
.ct-preloader {
  .loading-break {
    &.loading-dot-1 {
      background-color: $motive;
    }
    &.loading-dot-2 {
      background-color: $motive-dark;
    }
    &.loading-dot-3 {
      background-color: $motive-darker;
    }
  }
}

// Shoping cart // -----------------------------------------------------------------------------------------------------
.navbar.navbar-dark .ct-productCart .ct-product-item .ct-product-details .ct-product-name a {
  &:hover,
  &:active,
  &:focus {
    color: $motive;
  }
}

// Socials // ----------------------------------------------------------------------------------------------------------
.ct-socials {
  &.ct-socials--motive {
    li {
      a {
        border: 1px solid $motive;
        &:hover,
        &:focus {
          background-color: $motive;
        }
      }
    }
  }
}

// Contact box // ------------------------------------------------------------------------------------------------------
.ct-contactBox {
  .ct-contactBox-number {
    color: $motive;
  }
  a {
    &:hover,
    &:focus {
      .ct-contactBox-top {
        color: $motive;
      }
    }
  }
}

// Search link // ------------------------------------------------------------------------------------------------------
.ct-search-link {
  a {
    border: 1px solid $motive;
    &:hover,
    &:focus {
      background-color: $motive;
    }
  }
}

// Scroll to next section // -------------------------------------------------------------------------------------------
.ct-nextSection-scroll.ct-nextSection-scroll--motive {
  i {
    color: $motive;
  }
  &:hover,
  &:focus {
    span {
      color: $motive;
    }
  }
}

// Product preview // --------------------------------------------------------------------------------------------------
.ct-productPreview {
  .ct-iconBox {
    .ct-iconBox-decorativeLine {
      background-color: $accient;
      &:after {
        background-color: $accient;
      }
    }
  }
}

// Search form // ------------------------------------------------------------------------------------------------------
.ct-searchForm-close {
  &:hover,
  &:focus {
    svg {
      path {
        fill: $motive;
      }
    }
  }
}

.ct-search-btn {
  &:hover,
  &:focus {
    color: $motive;
  }
}

// Responsive social icons // ------------------------------------------------------------------------------------------
.ct-socials--responsive {
  li {
    a {
      &:before {
        background-color: $motive;
      }
      &:hover,
      &:focus {
        background-color: $motive-darker;
      }
    }
  }
}

// Shop locator // ------------------------------------------------------------------------------------------
.metro {
  .ct-googleMap--sidebar .ct-googleMap--sidebarItem {
    color: $motive;
    &:hover {
      background-color: $motive;
    }
  }
  .ct-googleMap--selectContainer:before,
  .ct-googleMap--searchContainer:before {
    background-color: $motive;
  }
  .active {
    color: $motive;
    &:hover {
      color: $motive;
    }
  }
}

// List // ------------------------------------------------------------------------------------------
.ct-list-decorated {
  a {
    &:hover {
      color: $motive;
    }
  }
}

// Image Box // ------------------------------------------------------------------------------------------
.ct-imageBox--inline {
  .ct-imageBox-content {
    border-left: 1px solid $motive;
  }
}
