//== Font Weights
//
//##

@each $font-weight in $font-weights {
  .ct-u-font-weight--#{$font-weight} {
    font-weight: $font-weight !important;
  }
}


//== Font Sizes
//
//##

@each $font-size in $font-sizes {
  .ct-u-font-size--#{$font-size} {
    font-size: $font-size + px !important;
  }
}


$ff-libre-baskerville  : 'Libre Baskerville', serif;
$ff-montserrat         : 'Montserrat', sans-serif;
$ff-open-sans          : 'Open Sans', sans-serif;

.ct-u-font-family-monsterrat{
  font-family: $ff-montserrat!important;
}

.ct-u-font-italic{
  font-style: italic;
}
