@each $color_name, $color_value in $colors {
  .ct-u-background--#{$color_name} {
    background-color: $color_value !important;
  }
  .ct-u-color--#{$color_name} {
    color: $color_value !important;
  }
}


$footerTop-bg-color:      #1a1a1a;
$footerBottom-bg-color:   #0d0d0d;