.ct-footer {
  .ct-footer-top {
    background-color: $footerTop-bg-color;
    padding-bottom: 50px;
    padding-top: 20px;
  }
  .ct-footer-bottom {
    background-color: $footerBottom-bg-color;
    font-family: $ff-montserrat;
  }
  .ct-footer-copyright {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @include xs-max {
    .ct-newsletter {
      margin-top: 15px;
      label {
        display: block;
        padding-right: 0;
      }
    }
  }
}

/* Copyright text */

.ct-copyright-text {
  display: inline-block;
  color: #535353;
  text-transform: uppercase;
  line-height: 30px;
  @include font-size(1.2);
  @include sm-max {
    position: relative;
    display: block;
    padding-bottom: 15px;
    text-align: center;
    line-height: 24px;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 30px;
      left: 50%;
      margin-left: -15px;
      bottom: 5px;
      height: 1px;
      @include mixin-background-color(255,255,255, .2);
    }
  }
  a {
    &:hover, &:focus, &:active {
      color: #fff;
    }
  }
}

.ct-list-terms {
  font-family: $ff-open-sans;
  text-align: right;
  text-transform: uppercase;
  font-weight: 400;
  @include sm-max {
    text-align: center;
  }
  li {
    padding: 0 30px 0 0 ;
    @include sm-max {
      padding-right: 15px;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      line-height: 30px;
      @include font-size(1.1);
      @include mixin-color-rgba(255,255,255,.5);
      &:hover, &:focus, &:active {
        color: #fff;
      }
    }
  }
}

.ct-payments {
  padding: 15px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #3d3d3d;
  @include sm-max {
    text-align: center;
  }
  .ct-payments-icons {
    text-align: right;
    @include sm-max {
      text-align: center;
    }
    .ct-payment-title {
      line-height: 30px;
      padding-right: 15px;
      @include font-size(1.2);
      @include mixin-color-rgba(83, 83, 83, .5);
      @include md-max {
        padding-right: 0;
      }
      @include sm-max {
        display: block;
      }
    }
  }
}

/* Menu footer */
.ct-footer-nav {
  padding-top: 25px;
  .ct-footer-brand {
    padding-top: 10px;
    @include sm-max {
      display: block;
      margin: 0 auto;
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }
  @include sm-min {
    padding-top: 35px;
  }
}
.ct-menu-footer {
  padding-top: 10px;
  li {
    a {
      text-transform: uppercase;
      @include font-size(1.4);
      @include mixin-color-rgba(255,255,255,.5);
      &:hover, &:focus, &:active {
        color: #fff;
      }
    }
    + li {
      padding-top: 10px;
    }
  }
  @include md-max {
    text-align: right;
  }
  @include sm-max {
    text-align: center;
  }
}

.ct-preFooter {
  border-bottom: 1px solid #484848;
  padding-bottom: 35px;
  @include sm-min {
    padding-bottom: 60px;
  }
}
